import React, { useEffect, useRef, useState } from "react";
import p5 from "p5";
import { Box, Grid, GridItem } from "@chakra-ui/react";

import { SoundHeader } from "./SoundHeader";
import { SoundGauge } from "./SoundGauge";
import { ArupLogoRed } from "../misc/ArupLogoRed";
import { average } from "../../utils/average";
import { getHeatmapData } from "../../usecases/getHeatmapData";

export const SoundViz = () => {
  const [decibels, setDecibels] = useState(0);
  const rootRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const sketchFunction = (sketch: any) => {
      const ranges = Math.round(10 + (decibels / 80) * 40);
      const width = window.innerWidth;
      const height = window.innerHeight;
      const topColor = sketch.color("#191636");
      const bottomColor = sketch.color("#ffffff");
      const setGradient = (
        x: any,
        y: any,
        w: any,
        h: any,
        startColor: any,
        endColor: any,
        axis: any
      ) => {
        sketch.noFill();

        if (axis === sketch.Y_AXIS) {
          // Top to bottom gradient
          for (let i = y; i <= y + h; i++) {
            let inter = sketch.map(i, y, y + h, 0, 1);
            let c = sketch.lerpColor(startColor, endColor, inter);
            sketch.stroke(c);
            sketch.line(x, i, x + w, i);
          }
        } else if (axis === sketch.X_AXIS) {
          // Left to right gradient
          for (let i = x; i <= x + w; i++) {
            let inter = sketch.map(i, x, x + w, 0, 1);
            let c = sketch.lerpColor(startColor, endColor, inter);
            sketch.stroke(c);
            sketch.line(i, y, i, y + h);
          }
        }
      };
      sketch.setup = () => {
        sketch.createCanvas(width, height);
      };

      sketch.draw = () => {
        setGradient(0, 0, width, height, topColor, bottomColor, sketch.X_AXIS);
        sketch.noFill();
        sketch.strokeWeight(0.4);

        for (let i = 0; i < ranges; i++) {
          // const paint = sketch.map(i, 0, ranges, 0, 255);
          // sketch.stroke(paint);
          sketch.stroke(255);

          sketch.beginShape();
          for (let x = -10; x < sketch.width + 11; x += 20) {
            const n = sketch.noise(
              x * 0.001,
              i * (0.01 + (decibels / 80) * 0.02),
              sketch.frameCount * 0.008
            );
            const y = sketch.map(n, 0, 1, 0, sketch.height);
            sketch.vertex(x, y);
          }
          sketch.endShape();
        }
      };
    };
    const elem = document.getElementsByClassName("p5Canvas")[0];
    if (elem) {
      elem.parentNode?.removeChild(elem);
    }
    const p5Instance = new p5(
      sketchFunction,
      rootRef.current === null ? undefined : rootRef.current
    );
    return () => p5Instance.remove();
  }, [decibels]);

  useEffect(() => {
    const updateDecibels = async () => {
      let soundData: string[] | undefined;
      try {
        soundData = await (
          await getHeatmapData("sound", "1F", "comfort")
        )?.values.map((it) => it.value);

        soundData = soundData && soundData.length > 0 ? soundData : ["0"];
      } catch (err) {
        soundData = ["0"];
      }

      const newValue = average(soundData);
      setDecibels(newValue);
    };
    const interval = setInterval(updateDecibels, 15 * 60000);
    updateDecibels();
    return () => clearInterval(interval);
  }, []);

  // Debug
  useEffect(() => {
    const setDecibelByKeyPress = (e: any) => {
      console.log("keyup", e.code, e.key);
      if (e.code === "Digit5") setDecibels(20);
      else if (e.code === "Digit6") setDecibels(40);
      else if (e.code === "Digit7") setDecibels(50);
      else if (e.code === "Digit8") setDecibels(60);
      else if (e.code === "Digit9") setDecibels(75);
    };
    document.addEventListener("keyup", setDecibelByKeyPress);
    return () => document.removeEventListener("keyup", setDecibelByKeyPress);
  }, []);
  return (
    <Box
      position="relative"
      ref={rootRef}
      backgroundImage="linear-gradient(#191636, #FFFFFF)"
    >
      <Box
        className="absolute-container"
        position="absolute"
        padding="5rem"
        width="calc(100vw - 10rem)"
        height="calc(100vh - 10rem)"
      >
        <Grid
          height="calc(100vh - 10rem)"
          templateRows="repeat(6, 1fr)"
          templateColumns="repeat(2, 1fr)"
          gap={0}
        >
          <GridItem rowStart={1} rowEnd={2} colStart={1} colEnd={1}>
            <SoundHeader decibels={decibels} />
          </GridItem>
          <GridItem rowStart={5} rowEnd={6} colStart={1} colEnd={1}>
            <SoundGauge decibels={decibels} />
          </GridItem>
          <GridItem rowStart={1} rowEnd={1} colStart={2} colEnd={2}>
            <ArupLogoRed />
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};
