import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

import { createGradientCSS } from "../../../usecases/createGradientCSS";

interface IProps {
  progress: number;
  range: number[];
  optimalRange?: number[];
  labels?: (number | string)[];
  colorPalette: { color: string; value: number }[];
  heightOverride?: string;
  paddingBottomOverride?: string;
}

const MeterBar: React.FC<IProps> = (props) => {
  const range = props.range[1] - props.range[0];
  const valueClamped = Math.min(
    Math.max(0, props.progress - props.range[0]),
    range
  );
  const widthBar = (valueClamped * 100) / range;

  const colorGradient: string = createGradientCSS(props.colorPalette, widthBar);

  return (
    <Box
      position="relative"
      width="100%"
      backgroundColor="#FFFFFF33"
      height={props.heightOverride ? props.heightOverride : "1rem"}
      marginBottom={
        props.paddingBottomOverride ? props.paddingBottomOverride : "0rem"
      }
      borderRadius="0.5rem"
    >
      <motion.div
        key={Math.random()}
        animate={{ width: ["0%", "0%", `${widthBar}%`] }}
        transition={{ ease: "easeOut", duration: 2.5 }}
        style={{ width: "0%", height: "100%", minWidth: "1rem" }}
      >
        <Box
          key={Math.random()}
          className="ColoredBar"
          width="100%"
          height="100%"
          background={colorGradient}
          borderRadius="0.5rem"
        />
      </motion.div>
      {props.optimalRange && (
        <Box
          className="OptimalRange"
          position="absolute"
          top="-0.375rem"
          left={`calc(-0.4rem + ${
            ((props.optimalRange[0] - props.range[0]) * 100) / range + ""
          }%)`}
          width={
            ((props.optimalRange[1] - props.optimalRange[0]) / range) * 100 +
            "%"
          }
          height={props.heightOverride ? props.heightOverride : "1rem"}
          padding="0.3rem"
          border="1px solid white"
          borderRadius="0.8rem"
        ></Box>
      )}
      <Box
        position="relative"
        height="0%"
        width="calc(100% - 1rem)"
        margin="0rem 0.5rem"
      >
        {props.labels &&
          props.labels.map((it) =>
            renderLabel(it, props.range[1], props.range[0])
          )}
      </Box>
    </Box>
  );
};

const renderLabel = (
  value: number | string,
  maxValue: number,
  minValue: number
) => {
  return (
    <Box
      key={value}
      position="absolute"
      top="0rem"
      left={((Number(value) - minValue) / (maxValue - minValue)) * 100 + "%"}
    >
      <Text
        position="absolute"
        top="-3.7rem"
        left="-0.4rem"
        textAlign="left"
        color="white"
        fontSize="0.9rem"
      >
        {value}
      </Text>
    </Box>
  );
};
export default MeterBar;
