import { Box } from "@chakra-ui/react";
import {
  P5Instance,
  ReactP5Wrapper,
  Sketch,
} from "../../misc/ReactP5WrapperComponent";

// https://openprocessing.org/sketch/1491135

const INTER_BLOBS = 2; // difference between the sizes of two blobs
const RADIUS = 150;
const LOWER_THRESHOLD = 20;
const UPPER_THRESHOLD = 25;

const noiseProg = (x: number) => x * x;

const sketch: Sketch = (p5: P5Instance) => {
  let kMax = 0;
  let step = 0;
  let blobs_number = 0;
  let max_noise = 0;
  let radius = RADIUS;

  p5.updateWithProps = (props) => {
    if (props.value) {
      if (props.value < LOWER_THRESHOLD) {
        max_noise = props.value;
        blobs_number = props.value * 0.7;
      } else if (props.value >= UPPER_THRESHOLD) {
        max_noise = props.value * 40;
        blobs_number = props.value * 1.2;
      } else {
        max_noise = props.value * 30;
        blobs_number = props.value;
      }
      radius = RADIUS + props.value * 2;
    }
    p5.setup();
  };

  p5.setup = () => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight);
    p5.colorMode(p5.RGB, 1);
    p5.angleMode(p5.DEGREES);
    p5.noFill();
    kMax = p5.random(0.5, 1.5);
    step = 5;
  };
  p5.draw = () => {
    p5.clear();
    p5.noStroke();
    for (let i = 0; i < blobs_number; i++) {
      let alpha = 1 - noiseProg(i / blobs_number);
      p5.stroke(255);
      p5.strokeWeight(alpha);
      let size = radius + i * INTER_BLOBS;
      let k = kMax * p5.sqrt(i / blobs_number);
      let noisiness = max_noise * noiseProg(i / blobs_number);
      blob(size, p5.width / 2, p5.height / 2, k, i * step, noisiness);
    }
  };

  function blob(
    size: number,
    xCenter: number,
    yCenter: number,
    k: number,
    t: number,
    noisiness: number
  ) {
    p5.beginShape();
    let angleStep = 360 / 40;
    for (let theta = 0; theta < 360; theta += angleStep) {
      let r1, r2;
      r1 = p5.cos(theta) + 1;
      r2 = p5.sin(theta) + 1;
      let r =
        size +
        p5.noise(
          p5.frameCount / 100 + k * r2,
          p5.frameCount / 300 + k * r1,
          t
        ) *
          noisiness;
      let x = xCenter + r * p5.cos(theta);
      let y = yCenter + r * p5.sin(theta);
      p5.curveVertex(x, y);
    }
    p5.endShape(p5.CLOSE);
  }
};

export const ProcessingRoundedNoise: React.FC<{
  value: number;
  backgroundColor: string;
}> = (props) => {
  return (
    <Box
      className="ProcessingRoundedNoise"
      position="relative"
      height="100%"
      width="100%"
      background={
        props.value === 0
          ? "#162431"
          : `linear-gradient(${props.backgroundColor},#162431)`
      }
      backgroundSize="cover"
    >
      <Box position="absolute" top="0" left="0" width="100vw" height="100vh">
        <ReactP5Wrapper
          sketch={sketch}
          value={props.value}
          backgroundColor={props.backgroundColor}
        ></ReactP5Wrapper>
      </Box>
    </Box>
  );
};
