import React, { useEffect, useState } from "react";
import { Floor } from "../misc/Floors";
import { Variable } from "./ComfortVariables";
import { ComfortTemplate } from "./templates/ComfortTemplate";

export const ComfortWrapper: React.FC<{
  showFloorDetails: boolean;
  variable: Variable;
  show: boolean;
}> = (props) => {
  const [floorValues, setFloorValues] = useState<Record<Floor, number>>({
    lowerGround: 0,
    groundFloor: 0,
    firstFloor: 0,
    outside: 0,
  });
  const currentValue = props.variable.currentValue(floorValues);
  useEffect(() => {
    const intervalId = setInterval(
      () => props.variable.getData().then((data) => setFloorValues(data)),
      1000 * 60 * 30
    );
    props.variable.getData().then((data) => setFloorValues(data));
    return clearInterval(intervalId);
  }, [props.variable]);

  if (!props.show) {
    return <></>;
  }
  return (
    <ComfortTemplate
      title={
        currentValue === 0
          ? ""
          : props.variable.titlesSubtitles.find(
              (it) => it.upperTreshold > currentValue
            )?.title
      }
      subtitle={
        currentValue === 0
          ? ""
          : props.variable.titlesSubtitles.find(
              (it) => it.upperTreshold > currentValue
            )?.subtitle
      }
      topic={props.variable.topic}
      value={currentValue}
      valueByFloor={floorValues}
      meterOptimalRange={props.variable.meterOptimalRangeByFloor}
      meterSubtitle={props.variable.meterSubtitle}
      meterRange={props.variable.meterRange}
      meterLabels={props.variable.meterLabels}
      optimalRange={props.variable.meterOptimalRange}
      units={props.variable.units}
      colorPalette={props.variable.colorPalette}
      backgroundColor={props.variable.backgroundColor(
        props.variable.colorPalette,
        currentValue,
        props.variable.meterRange
      )}
      processingGraphics={
        new URLSearchParams(window.location.search).get("animation") === "true"
          ? props.variable.processingAnimation
          : undefined
      }
      showFloorDetails={props.showFloorDetails}
      secondaryTitle={
        floorValues.outside !== 0
          ? `${parseInt(floorValues.outside + "")}${props.variable.units}`
          : ""
      }
      secondarySubtitle={floorValues.outside !== 0 ? "outside" : ""}
    />
  );
};
