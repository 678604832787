import React from "react";
import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import HeaderRoot from "../header/HeaderRoot";
import { MeterRoot } from "../meter/MeterRoot";
import HeatmapRoot from "../heatmap/HeatmapRoot";
import { HeatmapData } from "../../../domain/HeatmapData";
import { ColorPalette } from "../../colors";
import { motion } from "framer-motion";

interface IProps {
  title: string | undefined;
  subtitle: string | undefined;
  topic: string;
  titleFontSize: string;
  subtitleFontSize: string;
  value: number;
  meterRange: number[];
  meterSubtitle: string;
  units: string;
  backgroundColor: string;
  processingGraphics:
    | React.FC<{ value: number; backgroundColor: string }>
    | undefined;
  optimalRange?: number[];
  meterLabels: number[];
  heatmapData: HeatmapData[];
  desksUnderThreshold: string;
  desksUnderThresholdText: string;
  heatmapMinLabel: string;
  heatmapMaxLabel: string;
  floorplanPath: string;
  floorplanMaskPath: string;
  mapImageOffset: { x: number; y: number; s: number; width: number };
  colorPalette: ColorPalette[];
}

export const HeatmapTemplate: React.FC<IProps> = (props) => {
  return (
    <Box
      key={new Date().valueOf()}
      className="HeatmapTemplate"
      width="100vw"
      height="100vh"
      backgroundColor="gray"
    >
      <Box position="absolute" width="100%" height="100%" top="0" left="0">
        {props.processingGraphics ? (
          <props.processingGraphics
            value={props.value}
            backgroundColor={props.backgroundColor}
          />
        ) : (
          <Box
            height="100%"
            width="100%"
            background={
              props.value === 0
                ? "#162431"
                : `linear-gradient(${props.backgroundColor},#162431)`
            }
            backgroundSize="cover"
          />
        )}
      </Box>
      <Grid
        height="100%"
        templateRows="repeat(9, 1fr)"
        templateColumns="repeat(12, 1fr)"
        gap={0}
      >
        <GridItem
          rowStart={1}
          rowEnd={10}
          colStart={7}
          colEnd={13}
          padding="3rem"
          position="relative"
        >
          <motion.div
            key={Math.random()}
            animate={{ opacity: [0, 0, 5] }}
            transition={{ ease: "easeOut", duration: 4 }}
            style={{ opacity: 0, position: "relative", height: "100%" }}
          >
            <Box
              backgroundColor="#00000099"
              position="absolute"
              height="100vh"
              width="75vw"
              top="-3rem"
              left="-25vw"
            />
            <HeatmapRoot
              range={props.meterRange}
              heatmapData={props.heatmapData}
              minLabel={props.heatmapMinLabel}
              maxLabel={props.heatmapMaxLabel}
              colorPalette={props.colorPalette}
              floorplanPath={props.floorplanPath}
              floorplanMaskPath={props.floorplanMaskPath}
              mapImageOffset={props.mapImageOffset}
            />
          </motion.div>
        </GridItem>
        <GridItem
          rowStart={1}
          rowEnd={10}
          colStart={1}
          colEnd={7}
          padding="3rem"
        >
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <HeaderRoot
              topic={props.topic}
              title={props.title}
              subtitle={props.subtitle}
              titleFontSize={props.titleFontSize}
              subtitleFontSize={props.subtitleFontSize}
            ></HeaderRoot>
            <MeterRoot
              units={props.units}
              value={props.value}
              subtitle={props.meterSubtitle}
              optimalRange={props.optimalRange}
              labels={props.meterLabels}
              range={props.meterRange}
              secondaryTitle={props.desksUnderThreshold}
              secondarySubtitle={props.desksUnderThresholdText}
              colorPalette={props.colorPalette}
              justifyContent="space-between"
            ></MeterRoot>
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};
