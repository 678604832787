export const createGradientCSS = (
  colors: { color: string; value: number }[],
  width: number
): string => {
  var result = "linear-gradient(to right";
  for (var i = 0; i < colors.length; i++) {
    result += `, ${colors[i].color} ${(colors[i].value / width) * 100}%`;
  }
  result += ")";
  return result;
};
