import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { ArupLogoWhite } from "../../misc/ArupLogoWhite";
import FloorDetailBox from "./FloorDetailBox";

interface IProps {
  progress: {
    lowerGround: number;
    groundFloor: number;
    firstFloor: number;
    outside?: number;
  };
  optimalRange?: {
    lowerGround: number[];
    groundFloor: number[];
    firstFloor: number[];
    outside: number[];
  };
  range: number[];
  colorPalette: { color: string; value: number }[];
  units: string;
}

const FloorDetailRoot: React.FC<IProps> = (props) => {
  return (
    <Box height="calc(100% - 2rem)" width="100%" marginBottom="2rem">
      <ArupLogoWhite />
      <Flex
        className="FloorDetailRoot-flex"
        gap="2.5rem"
        flexDirection="column"
        justifyContent="flex-end"
        height="calc(100% - 3rem)"
      >
        <FloorDetailBox
          units={props.units}
          floorName="First floor"
          progress={props.progress.firstFloor}
          optimalRange={props.optimalRange?.firstFloor}
          range={props.range}
          colorPalette={props.colorPalette}
        />
        <FloorDetailBox
          units={props.units}
          floorName="Ground floor"
          progress={props.progress.groundFloor}
          optimalRange={props.optimalRange?.groundFloor}
          range={props.range}
          colorPalette={props.colorPalette}
        />
        {props.progress.lowerGround && props.progress.lowerGround > 0 ? (
          <FloorDetailBox
            units={props.units}
            floorName="Lower ground"
            progress={props.progress.lowerGround}
            optimalRange={props.optimalRange?.lowerGround}
            range={props.range}
            colorPalette={props.colorPalette}
          />
        ) : (
          <></>
        )}
      </Flex>
    </Box>
  );
};
export default FloorDetailRoot;
