import { Box, Grid, GridItem, GridProps } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

const LightHorizontalBar: React.FC = () => {
	const numberOfBars = 96; //48*30min = 24h

	const gridBaseStyle: GridProps = {
		gridTemplateColumns: `repeat(${numberOfBars}, 1fr)`,
		gridTemplateRows: "1fr",
	};
	return (
		<Box width="100vw" position={"absolute"} bottom="2rem">
			<motion.div
				key="LightHorizontalBar"
				animate={{ y: [50, 50, 0], opacity: [0, 0, 1] }}
				transition={{ ease: "easeInOut", duration: 1.1 }}
				style={{ opacity: 0 }}
			>
				<Grid gridTemplateRows="2fr" {...gridBaseStyle} fontWeight="bold">
					<GridItem colStart={4} colSpan={94}>
						<Box border="1px solid rgba(0, 0, 0,0.25)" height={"9px"}></Box>
					</GridItem>
					<GridItem colStart={4} colSpan={1}>
						8pm
					</GridItem>
					<GridItem colStart={20} colSpan={1}>
						12pm
					</GridItem>
					<GridItem colStart={36} colSpan={1}>
						4am
					</GridItem>
					<GridItem colStart={52} colSpan={1}>
						8am
					</GridItem>
					<GridItem colStart={68} colSpan={1}>
						12am
					</GridItem>
					<GridItem colStart={82} colSpan={1}>
						4pm
					</GridItem>
					<GridItem colStart={96} colSpan={1}>
						7pm
					</GridItem>
				</Grid>
			</motion.div>
		</Box>
	);
};

export default LightHorizontalBar;
