export const airPalette: ColorPalette[] = [
  { color: "#FFDF4C", value: 0 },
  { color: "#52C4BA", value: 75 },
  { color: "#43B3EA", value: 100 },
];
export const soundPalette: ColorPalette[] = [
  { color: "#43B3EA", value: 0 },
  { color: "#A76BC7", value: 75 },
  { color: "#DE63B1", value: 100 },
];
export const temperaturePalette: ColorPalette[] = [
  { color: "#5BC5B1", value: 0 },
  { color: "#85C987", value: 75 },
  { color: "#F69446", value: 100 },
];

export const co2Palette: ColorPalette[] = [
  { color: "#54C3BC", value: 0 },
  { color: "#5AC482", value: 75 },
  { color: "#F69446", value: 100 },
];

export const pm25Palette: ColorPalette[] = [
  { color: "#44B3EA", value: 0 },
  { color: "#51C5B6", value: 75 },
  { color: "#F69446", value: 100 },
];

export const energyPalette: ColorPalette[] = [
  { color: "#6CC361", value: 0 },
  { color: "#B2CA38", value: 75 },
  { color: "#FBD10D", value: 100 },
];

export const waterPalette: ColorPalette[] = [
  { color: "#58C0BB", value: 0 },
  { color: "#3CA9E1", value: 75 },
  { color: "#045FAE", value: 100 },
];

export interface ColorPalette {
  color: string;
  value: number;
}
