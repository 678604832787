import React from "react";
import { Box, Text } from "@chakra-ui/react";
import MeterBar from "../meter/MeterBar";
import { motion } from "framer-motion";

interface IProps {
  floorName: string;
  progress: number;
  optimalRange?: number[];
  range: number[];
  colorPalette: { color: string; value: number }[];
  units: string;
}

const FloorDetailBox: React.FC<IProps> = (props) => {
  return (
    <Box>
      <Text
        color="white"
        height="2rem"
        fontSize="2rem"
        margin="1rem 0rem"
        fontFamily="Arial"
      >
        {props.floorName}
      </Text>
      <Box
        textAlign="left"
        color="white"
        fontSize="4rem"
        height="4rem"
        margin="0rem 0rem 1rem 0rem"
        fontFamily="Arial"
      >
        <motion.div
          key={Math.random()}
          animate={{ x: [-30, 0], opacity: [0, 1] }}
          transition={{ ease: "easeOut", duration: 0.5 }}
          style={{ opacity: 0 }}
        >
          <b>{Math.round(props.progress)}</b>
          <Box
            display="inline"
            textAlign="left"
            color="white"
            fontSize="2rem"
            fontFamily="Arial"
          >
            {props.units}
          </Box>
        </motion.div>
      </Box>
      <MeterBar
        progress={props.progress}
        optimalRange={props.optimalRange}
        range={props.range}
        colorPalette={props.colorPalette}
      />
    </Box>
  );
};
export default FloorDetailBox;
