import { Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { P5Instance, Sketch } from "../../misc/ReactP5WrapperComponent";

import p5 from "p5";

interface IProps {
	element: {
		ts: number;
		colorTemperature: string;
		illuminance: string;
		radiation: { label: string; value: number }[];
		isCurrentElement: boolean;
	};
	maxRadiation: number;
}
const CurrentRadiationMeter: React.FC<IProps> = (props) => {
	const { element, maxRadiation } = props;
	const { radiation, isCurrentElement } = element;
	const ref = React.useRef<any>(null);

	React.useEffect(() => {
		const sketch: Sketch = (p5: P5Instance) => {
			const barWidth = window.innerWidth;
			const barHeight = window.innerHeight / 2;

			p5.setup = () => {
				p5.createCanvas(barWidth, barHeight);
				p5.clear();

				p5.stroke("rgb(78,78,103)");

				isCurrentElement ? p5.strokeWeight(4) : p5.strokeWeight(0.75);

				const arrayPoints = radiation
					? radiation.map((e: { label: string; value: number }) => {
							return e.value;
					  })
					: [];

				const maxScaleValue = maxRadiation > 15000 ? maxRadiation + 100 : 15000;

				const currentElementMaxValue = Math.max(...arrayPoints);

				if (currentElementMaxValue < 1000 && !isCurrentElement) {
					return;
				}

				arrayPoints.forEach((pointValue, index) => {
					const y1 = ((maxScaleValue - pointValue) / maxScaleValue) * barHeight;
					const x1 = ((5 * index) / (740 - 380)) * barWidth;

					if (index < arrayPoints.length - 1) {
						//draw line
						const y2 = ((maxScaleValue - arrayPoints[index + 1]) / maxScaleValue) * barHeight;
						const x2 = ((5 * (index + 1)) / (740 - 380)) * barWidth;
						p5.line(x1, y1, x2, y2);
					}
				});
				p5.strokeWeight(1);
			};
		};
		const p5Instance = new p5(sketch, ref.current === null ? undefined : ref.current);
		return () => {
			console.log("removed");
			p5Instance.remove();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [element]);
	return (
		<Box width="100vw" height="100vh" position={"absolute"} zIndex="100">
			<motion.div
				key="LightVerticalBar"
				animate={{ y: [200, 200, 0], opacity: [0, 0, 1] }}
				transition={{ ease: "easeIn", duration: 1.1 }}
				style={{ opacity: 0, height: "100%" }}
			>
				<Box ref={ref} position={"absolute"} width="100%" height="50%" top="30%"></Box>
			</motion.div>
		</Box>
	);
};

export default CurrentRadiationMeter;
