import { BrowserRouter, Route, Routes } from "react-router-dom";

import { ComfortWrapper } from "./components/screens/ComfortWrapper";
import { HeatmapWrapper } from "./components/screens/HeatmapWrapper";
import { LoopWrapper } from "./components/screens/loops/LoopWrapper";
import {
	co2Data,
	energyData,
	pm25Data,
	soundData,
	temperatureData,
	waterData,
} from "./components/screens/ComfortVariables";
import { lightData } from "./components/screens/LightVariables";
import LandingRoot from "./components/landing/LandingRoot";
import { Prototype } from "./components/prototype/Prototype";
import { SoundViz } from "./components/prototype/SoundViz";
import {
	heatmapCo2Data,
	heatmapPm25Data,
	heatmapSoundData,
	heatmapTempData,
} from "./components/screens/HeatmapVariables";
import { LightWrapper } from "./components/screens/LightWrapper";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<LandingRoot />} />
					<Route path="/loop/1F" element={<LoopWrapper mode="1F" />} />
					<Route path="/loop/GF" element={<LoopWrapper mode="GF" />} />
					<Route path="/loop" element={<LoopWrapper mode="general" />} />

					<Route path="/prototype" element={<Prototype />} />
					<Route path="/prototype/sound" element={<SoundViz />} />
					<Route
						path="/comfort/sound"
						element={<ComfortWrapper show={true} showFloorDetails={true} variable={soundData} />}
					/>
					<Route
						path="/comfort/light"
						element={<LightWrapper show={true} showFloorDetails={false} variable={lightData} />}
					/>
					<Route
						path="/comfort/light/sample"
						element={<LightWrapper show={true} showFloorDetails={false} variable={lightData} />}
					/>
					<Route
						path="/comfort/temperature"
						element={<ComfortWrapper show={true} showFloorDetails={true} variable={temperatureData} />}
					/>
					<Route
						path="/comfort/co2"
						element={<ComfortWrapper show={true} showFloorDetails={true} variable={co2Data} />}
					/>
					<Route
						path="/comfort/pm25"
						element={<ComfortWrapper show={true} showFloorDetails={true} variable={pm25Data} />}
					/>
					<Route
						path="/planet/energy"
						element={<ComfortWrapper show={true} variable={energyData} showFloorDetails={false} />}
					/>
					<Route
						path="/planet/water"
						element={<ComfortWrapper show={true} variable={waterData} showFloorDetails={false} />}
					/>
					<Route
						path="/comfort/sound/heatmap/1F"
						element={<HeatmapWrapper floor="1F" show={true} comfortData={soundData} heatmapData={heatmapSoundData} />}
					/>
					<Route
						path="/comfort/sound/heatmap/GF"
						element={<HeatmapWrapper floor="GF" show={true} comfortData={soundData} heatmapData={heatmapSoundData} />}
					/>
					<Route
						path="/comfort/temperature/heatmap/1F"
						element={
							<HeatmapWrapper floor="1F" show={true} comfortData={temperatureData} heatmapData={heatmapTempData} />
						}
					/>
					<Route
						path="/comfort/temperature/heatmap/GF"
						element={
							<HeatmapWrapper floor="GF" show={true} comfortData={temperatureData} heatmapData={heatmapTempData} />
						}
					/>
					<Route
						path="/comfort/CO2/heatmap/1F"
						element={<HeatmapWrapper floor="1F" show={true} comfortData={co2Data} heatmapData={heatmapCo2Data} />}
					/>
					<Route
						path="/comfort/CO2/heatmap/GF"
						element={<HeatmapWrapper floor="GF" show={true} comfortData={co2Data} heatmapData={heatmapCo2Data} />}
					/>

					<Route
						path="/comfort/pm25/heatmap/1F"
						element={<HeatmapWrapper floor="1F" show={true} comfortData={pm25Data} heatmapData={heatmapPm25Data} />}
					/>
					<Route
						path="/comfort/pm25/heatmap/GF"
						element={<HeatmapWrapper floor="GF" show={true} comfortData={pm25Data} heatmapData={heatmapPm25Data} />}
					/>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
