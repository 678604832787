import React, { useEffect, useState } from "react";
import { co2Data, pm25Data, temperatureData } from "../ComfortVariables";
import { ComfortWrapper } from "../ComfortWrapper";
import {
  heatmapCo2Data,
  heatmapPm25Data,
  heatmapTempData,
} from "../HeatmapVariables";
import { HeatmapWrapper } from "../HeatmapWrapper";

interface IProps {
  mode: "general" | "1F" | "GF";
}

const comfortVariables = [temperatureData, pm25Data, co2Data];

export const LoopWrapper: React.FC<IProps> = (props) => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const id = setInterval(() => {
      const newIndex = index + 1;
      if (newIndex === comfortVariables.length) {
        window.location.reload();
        // setIndex(0);
      } else {
        setIndex(newIndex);
      }
    }, 30 * 1000);
    return () => clearInterval(id);
  }, [index, props]);

  if (props.mode === "general") {
    return (
      <>
        <ComfortWrapper
          showFloorDetails={true}
          variable={comfortVariables[0]}
          show={index === 0}
        />
        <ComfortWrapper
          showFloorDetails={true}
          variable={comfortVariables[1]}
          show={index === 1}
        />
        <ComfortWrapper
          showFloorDetails={true}
          variable={comfortVariables[2]}
          show={index === 2}
        />
      </>
    );
  }
  if (props.mode === "1F") {
    return (
      <>
        <HeatmapWrapper
          floor="1F"
          show={index === 0}
          comfortData={temperatureData}
          heatmapData={heatmapTempData}
        />
        <HeatmapWrapper
          floor="1F"
          show={index === 1}
          comfortData={pm25Data}
          heatmapData={heatmapPm25Data}
        />
        <HeatmapWrapper
          floor="1F"
          show={index === 2}
          comfortData={co2Data}
          heatmapData={heatmapCo2Data}
        />
      </>
    );
  }
  if (props.mode === "GF") {
    return (
      <>
        <HeatmapWrapper
          floor="GF"
          show={index === 0}
          comfortData={temperatureData}
          heatmapData={heatmapTempData}
        />
        <HeatmapWrapper
          floor="GF"
          show={index === 1}
          comfortData={pm25Data}
          heatmapData={heatmapPm25Data}
        />
        <HeatmapWrapper
          floor="GF"
          show={index === 2}
          comfortData={co2Data}
          heatmapData={heatmapCo2Data}
        />
      </>
    );
  }
  return <></>;
};
