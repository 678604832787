import React from "react";
import { Flex, Center, SimpleGrid, Link } from "@chakra-ui/react";

interface IProps {}

const Item: React.FC<IProps> = (props) => {
  return (
    <Center
      padding="1rem"
      background="white"
      borderRadius="0.5rem"
      height="80px"
      boxShadow="2px 2px 4px gray"
    >
      {props.children}
    </Center>
  );
};

const LandingRoot: React.FC<IProps> = (props) => {
  return (
    <Flex
      background="#F5F6F8"
      height="100vh"
      width="100vw"
      flexDirection="column"
    >
      <SimpleGrid columns={2} spacing="2rem" margin="2rem">
        <Item>
          <Link href="/loop/1F" color="black" fontFamily="Arial">
            Loop 1F
          </Link>
        </Item>
        <Item>
          <Link href="/loop/GF" color="black" fontFamily="Arial">
            Loop GF
          </Link>
        </Item>
        <Item>
          <Link href="/loop" color="black" fontFamily="Arial">
            Loop general
          </Link>
        </Item>
        <Item>
          <Link href="/comfort/light" color="black" fontFamily="Arial">
            Light
          </Link>
        </Item>
      </SimpleGrid>
    </Flex>
  );
};
export default LandingRoot;
