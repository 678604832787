import React from "react";
import { Flex } from "@chakra-ui/react";

export const ArupLogoRed = () => {
  return (
    <Flex position="relative" height="3rem" flexDirection="row-reverse">
      <img src="/logo-home-new--red.svg" alt="Arup logo in red" height="100%" />
    </Flex>
  );
};
