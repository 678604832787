import { HeatmapData } from "../../domain/HeatmapData";
import { FloorName, getHeatmapData } from "../../usecases/getHeatmapData";
import { average } from "../../utils/average";
import { ProcessingFlow } from "./animation/ProcessingFlow";
import { ProcessingLineGraphics } from "./animation/ProcessingLineGraphics";
import { ProcessingParticlesGraphics } from "./animation/ProcessingParticlesGraphics";
import { ProcessingRoundedNoise } from "./animation/ProcessingRoundedNoise";
import { firstFloorOffset, groundFloorOffset } from "./heatmap/Offsets";
import { co2Text, pm25Text, soundText, tempText } from "./Texts";

export type HeatmapVariable = {
  averageFunction: (array: number[] | string[]) => number;
  request: (floor: FloorName) => Promise<{
    values: HeatmapData[];
  }>;
  title: (currentValue: number) => string | undefined;
  subtitle: (currentValue: number) => string | undefined;
  titleFontSize: string;
  subtitleFontSize: string;
  meterSubtitle: string;
  processingGraphics: React.FC<any>;
  desksUnderThreshold?: (heatmapData: HeatmapData[]) => string;
  outsideLabel?: (outsideValue: number, units: string) => string;
  desksUnderThresholdText: string;
  heatmapMinLabel: string;
  heatmapMaxLabel: string;
  floorplanPath: (floor: string) => string;
  floorplanMaskPath: (floor: string) => string;
  mapImageOffset: (floor: string) => {
    x: number;
    y: number;
    s: number;
    width: number;
  };
};

export const heatmapSoundData: HeatmapVariable = {
  averageFunction: average,
  request: async (floor: FloorName) => {
    return await getHeatmapData("sound", floor, "comfort");
  },
  title: (currentValue: number) =>
    soundText.find((it) => it.upperTreshold > currentValue)?.title,
  subtitle: (currentValue: number) =>
    soundText.find((it) => it.upperTreshold > currentValue)?.subtitle,
  titleFontSize: "4.75rem",
  subtitleFontSize: "3.2rem",
  meterSubtitle: "in the floor",
  processingGraphics: ProcessingLineGraphics,
  desksUnderThreshold: (heatmapData: HeatmapData[]) => {
    if (heatmapData.length > 0) {
      return heatmapData
        .filter((it) => +it.value > 0)
        .sort((a, b) => Number(a.value) - Number(b.value))[0].name;
    } else {
      return "-";
    }
  },
  outsideLabel: undefined,
  desksUnderThresholdText: "quietest desk available now",
  heatmapMinLabel: "Quiet",
  heatmapMaxLabel: "All happening here",
  floorplanPath: (floor: string) =>
    floor === "1F" ? "/floorplan-1F.png" : "/floorplan-GF.png",
  floorplanMaskPath: (floor: string) =>
    floor === "1F" ? "/floorplan-1F-mask.png" : "/floorplan-GF-mask.png",
  mapImageOffset: (floor: string) =>
    floor === "1F" ? firstFloorOffset : groundFloorOffset,
};

export const heatmapTempData: HeatmapVariable = {
  averageFunction: average,
  request: async (floor: FloorName) => {
    return await getHeatmapData("temperature", floor, "comfort");
  },
  title: (currentValue: number) =>
    tempText.find((it) => it.upperTreshold > currentValue)?.title,
  subtitle: (currentValue: number) =>
    tempText.find((it) => it.upperTreshold > currentValue)?.subtitle,
  titleFontSize: "4.75rem",
  subtitleFontSize: "3.2rem",
  meterSubtitle: "in the floor",
  processingGraphics: ProcessingRoundedNoise,
  desksUnderThreshold: undefined,
  outsideLabel: (outsideValue: number, units: string) =>
    `${parseInt(outsideValue + "")}${units} outside`,
  desksUnderThresholdText: "",
  heatmapMinLabel: "Cool",
  heatmapMaxLabel: "Warm",
  floorplanPath: (floor: string) =>
    floor === "1F" ? "/floorplan-1F.png" : "/floorplan-GF.png",
  floorplanMaskPath: (floor: string) =>
    floor === "1F" ? "/floorplan-1F-mask.png" : "/floorplan-GF-mask.png",
  mapImageOffset: (floor: string) =>
    floor === "1F" ? firstFloorOffset : groundFloorOffset,
};

export const heatmapCo2Data: HeatmapVariable = {
  averageFunction: average,
  request: async (floor: FloorName) => {
    return await getHeatmapData("co2", floor, "comfort");
  },
  title: (currentValue: number) =>
    co2Text.find((it) => it.upperTreshold > currentValue)?.title,
  subtitle: (currentValue: number) =>
    co2Text.find((it) => it.upperTreshold > currentValue)?.subtitle,
  titleFontSize: "4.75rem",
  subtitleFontSize: "3.2rem",
  meterSubtitle: "in the floor",
  processingGraphics: ProcessingFlow,
  desksUnderThreshold: undefined,
  outsideLabel: (outsideValue: number, units: string) =>
    `${parseInt(outsideValue + "")}${units} outside`,
  desksUnderThresholdText: "",
  heatmapMinLabel: "Excellent air quality",
  heatmapMaxLabel: "Busy",
  floorplanPath: (floor: string) =>
    floor === "1F" ? "/floorplan-1F.png" : "/floorplan-GF.png",
  floorplanMaskPath: (floor: string) =>
    floor === "1F" ? "/floorplan-1F-mask.png" : "/floorplan-GF-mask.png",
  mapImageOffset: (floor: string) =>
    floor === "1F" ? firstFloorOffset : groundFloorOffset,
};

export const heatmapPm25Data: HeatmapVariable = {
  averageFunction: average,
  request: async (floor: FloorName) => {
    return await getHeatmapData("pm25", floor, "comfort");
  },
  title: (currentValue: number) =>
    pm25Text.find((it) => it.upperTreshold > currentValue)?.title,
  subtitle: (currentValue: number) =>
    pm25Text.find((it) => it.upperTreshold > currentValue)?.subtitle,
  titleFontSize: "4.75rem",
  subtitleFontSize: "3.2rem",
  meterSubtitle: "in the floor",
  processingGraphics: ProcessingParticlesGraphics,
  desksUnderThreshold: undefined,
  outsideLabel: (outsideValue: number, units: string) =>
    `${parseInt(outsideValue + "")}${units} outside`,
  desksUnderThresholdText: "",
  heatmapMinLabel: "Low concentration",
  heatmapMaxLabel: "High concentration",
  floorplanPath: (floor: string) =>
    floor === "1F" ? "/floorplan-1F.png" : "/floorplan-GF.png",
  floorplanMaskPath: (floor: string) =>
    floor === "1F" ? "/floorplan-1F-mask.png" : "/floorplan-GF-mask.png",
  mapImageOffset: (floor: string) =>
    floor === "1F" ? firstFloorOffset : groundFloorOffset,
};
