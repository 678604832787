import { Box, Flex, Grid, GridItem } from "@chakra-ui/react";
import React from "react";
import HeaderRoot from "../header/HeaderRoot";
import FloorDetailRoot from "../lateral-detail/FloorDetailRoot";
import { MeterRoot } from "../meter/MeterRoot";

interface IProps {
  title: string | undefined;
  subtitle: string | undefined;
  topic: string;
  value: number;
  valueByFloor: {
    lowerGround: number;
    groundFloor: number;
    firstFloor: number;
  };
  meterOptimalRange?: {
    lowerGround: number[];
    groundFloor: number[];
    firstFloor: number[];
  };
  meterRange: number[];
  meterSubtitle: string;
  units: string;
  colorPalette: {
    color: string;
    value: number;
  }[];
  backgroundColor: string;
  processingGraphics:
    | React.FC<{ value: number; backgroundColor: string }>
    | undefined;
  optimalRange?: number[];
  meterLabels: (number | string)[];
  showFloorDetails: boolean;
  secondaryTitle: string;
  secondarySubtitle: string;
}

export const ComfortTemplate: React.FC<IProps> = (props) => {
  return (
    <Box
      className="ComfortTemplate"
      width="100vw"
      height="100vh"
      backgroundColor="gray"
    >
      <Box
        position="absolute"
        width="100%"
        height="100%"
        top="0"
        left="0"
        zIndex={0}
      >
        {props.processingGraphics ? (
          <props.processingGraphics
            value={props.value}
            backgroundColor={props.backgroundColor}
          />
        ) : (
          <Box
            height="100%"
            width="100%"
            background={
              props.value === 0
                ? "#162431"
                : `linear-gradient(${props.backgroundColor},#162431)`
            }
            backgroundSize="cover"
          />
        )}
      </Box>
      <Grid
        height="100%"
        templateRows="repeat(9, 1fr)"
        templateColumns="repeat(12, 1fr)"
        gap={0}
        position="absolute"
        width="100%"
        top="0"
        left="0"
        zIndex={1}
      >
        <GridItem
          rowStart={1}
          rowEnd={10}
          colStart={1}
          colEnd={10}
          padding="3rem"
        >
          <Flex
            height="100%"
            flexDirection="column"
            justifyContent="space-between"
          >
            <HeaderRoot
              topic={props.topic}
              title={props.title}
              subtitle={props.subtitle}
            ></HeaderRoot>
            <MeterRoot
              units={props.units}
              value={props.value}
              subtitle={props.meterSubtitle}
              optimalRange={props.optimalRange}
              labels={props.meterLabels}
              range={props.meterRange}
              colorPalette={props.colorPalette}
              secondaryTitle={props.secondaryTitle}
              secondarySubtitle={props.secondarySubtitle}
              justifyContent="flex-start"
            ></MeterRoot>
          </Flex>
        </GridItem>
        {props.showFloorDetails && (
          <GridItem rowStart={1} rowEnd={10} colStart={10} colEnd={13}>
            <Box
              key={Math.random()}
              style={{
                width: "calc(100% - 6rem)",
                height: "calc(100% - 6rem)",
                backgroundColor: "#191E23BF",
                padding: "3rem",
              }}
            >
              <FloorDetailRoot
                progress={props.valueByFloor}
                units={props.units}
                range={props.meterRange}
                colorPalette={props.colorPalette}
              ></FloorDetailRoot>
            </Box>
          </GridItem>
        )}
      </Grid>
    </Box>
  );
};
