import { Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";

const LightVerticalBar: React.FC<{ maxLuminanceScale: number }> = (props) => {
	const { maxLuminanceScale } = props;
	return (
		<Box width="100vw" height="100vh" position={"absolute"} zIndex="100">
			<motion.div
				key="LightVerticalBar"
				animate={{ x: [-50, -50, 0], opacity: [0, 0, 1] }}
				transition={{ ease: "easeInOut", duration: 1.1 }}
				style={{ opacity: 0, height: "100%" }}
			>
				<Box position={"absolute"} height="50%">
					<Text
						transform={"rotate(90deg)"}
						position={"absolute"}
						fontFamily="Arial"
						color="#000"
						fontWeight={"700"}
						fontSize="13px"
						bottom={"-20"}
						right={"0.5rem"}
					>
						0
					</Text>
					<Text
						transform={"rotate(90deg)"}
						position={"absolute"}
						fontFamily="Arial"
						color="#000"
						fontWeight={"700"}
						fontSize="13px"
					>
						{maxLuminanceScale / 1000},000
					</Text>
					<Box
						height="100%"
						alignSelf="stretch"
						width="0.25rem"
						border="1px solid rgba(0, 0, 0,0.25)"
						zIndex={"1"}
						marginLeft={window.innerWidth / 48 - 5}
					></Box>
				</Box>

				{/* <Box position={"absolute"} height="50%" bottom="0">
					<Text
						transform={"rotate(90deg)"}
						position={"absolute"}
						fontFamily="Arial"
						color="#000"
						fontWeight={"700"}
						fontSize="13px"
						top={"220px"}
						right="1"
					>
						740
					</Text>
					<Text
						top={"10%"}
						transform={"rotate(90deg)"}
						position={"absolute"}
						fontFamily="Arial"
						color="#000"
						fontWeight={"700"}
						fontSize="13px"
						right="1"
					>
						380
					</Text>

					<Box
						marginTop={"50"}
						height="200px"
						alignSelf="stretch"
						width="0.25rem"
						border="1px solid rgba(0, 0, 0,0.25)"
						zIndex={"1"}
						marginLeft={window.innerWidth / 48 - 5}
					></Box>
				</Box> */}
			</motion.div>
		</Box>
	);
};

export default LightVerticalBar;
