import { Box } from "@chakra-ui/react";
import { Vector } from "p5";
import {
  P5Instance,
  ReactP5Wrapper,
  Sketch,
} from "../../misc/ReactP5WrapperComponent";

// https://openprocessing.org/sketch/1250011

const sketch: Sketch = (p5: P5Instance) => {
  const past_pulses: any[] = []; // List that contains the past pulses positions
  let t = 0; // Time
  const N = 150; // Numer of waves

  // HARMONIC OSCILLATOR PARAMETERS
  const A = 30; // Amplitude
  const freq = 1; // Hz (more or less)

  p5.updateWithProps = (props) => {};

  p5.setup = () => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight);
    p5.noFill();
    p5.stroke(255);
    for (let i = 0; i < 150; i++) {
      draw(false);
    }
    console.log("setup", t);
  };

  function draw(draw: boolean) {
    if (p5.frameCount < 60 * 4) {
      return;
    }
    p5.clear();
    let pos: Vector = p5.createVector(
      p5.width / 2 + A * p5.cos((t * freq) / 10),
      p5.height / 2 + A * p5.sin((t * freq * 0.1) / 10)
    );
    const n = past_pulses.length;
    past_pulses[n] = pos;
    if (draw) {
      for (let i = 0; i < n; i++) {
        p5.strokeWeight(i / n);
        p5.circle(past_pulses[i].x, past_pulses[i].y, (1000 * (N - i)) / N);
      }
    }
    if (n === N) {
      past_pulses.shift();
    }
    t++;
  }

  p5.draw = () => {
    draw(true);
  };
};

export const ProcessingDopplerEffect: React.FC<{
  value: number;
  backgroundColor: string;
}> = (props) => {
  return (
    <Box
      className="ProcessingDopplerEffect"
      position="relative"
      height="100%"
      width="100%"
      background={
        props.value === 0
          ? "#162431"
          : `linear-gradient(${props.backgroundColor},#162431)`
      }
      backgroundSize="cover"
    >
      <Box position="absolute" top="0" left="0" width="100vw" height="100vh">
        <ReactP5Wrapper
          sketch={sketch}
          value={props.value}
          backgroundColor={props.backgroundColor}
        ></ReactP5Wrapper>
      </Box>
      {props.children}
    </Box>
  );
};
