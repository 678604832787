import { Box, Flex, Text } from "@chakra-ui/react";
import React from "react";

interface IProps {
  value: number;
  subtitle: string;
  units: string;
}

const MeterText: React.FC<IProps> = (props) => {
  const value: string = "" + Math.round(props.value);
  return (
    <Box
      textAlign="left"
      color={"white"}
      margin="0rem 0rem 5rem 0rem"
      fontFamily="Arial"
    >
      <Flex
        width="100%"
        height="100%"
        flexDirection="column"
        justifyContent="flex-end"
      >
        <Box>
          <Box
            display="inline"
            fontSize={value.length >= 3 ? "8rem" : "11rem"}
            fontWeight="bold"
          >
            {value}
          </Box>
          <Box
            display="inline"
            color="white"
            textAlign="left"
            fontSize="5.5rem"
            margin="0rem"
            fontFamily="Arial"
          >
            {props.units}
          </Box>
        </Box>
        <Text
          textAlign="left"
          fontSize="3rem"
          margin="0rem 0rem 0rem 0rem"
          fontFamily="Arial"
        >
          {props.subtitle}
        </Text>
      </Flex>
    </Box>
  );
};
export default MeterText;
