export const tempText = [
  {
    upperTreshold: 18,
    title: "Cool",
    subtitle: "",
  },
  {
    upperTreshold: 22,
    title: "",
    subtitle: "",
  },
  {
    upperTreshold: 24,
    title: "",
    subtitle: "",
  },
  {
    upperTreshold: 26,
    title: "",
    subtitle: "",
  },
  {
    upperTreshold: 9999,
    title: "Warm",
    subtitle: "",
  },
];

export const soundText = [
  {
    upperTreshold: 40,
    title: "It's oh so quiet!",
    subtitle: "",
  },
  {
    upperTreshold: 45,
    title: "Relatively quiet",
    subtitle: "",
  },
  {
    upperTreshold: 55,
    title: "A healthy buzz",
    subtitle: "",
  },
  {
    upperTreshold: 60,
    title: "Vibrant space",
    subtitle: "",
  },
  {
    upperTreshold: 9999,
    title: "It's all happening here!",
    subtitle: "",
  },
];

export const co2Text = [
  {
    upperTreshold: 600,
    title: "Excellent air quality",
    subtitle: "Well ventilated",
  },
  {
    upperTreshold: 800,
    title: "Excellent air quality",
    subtitle: "Well ventilated",
  },
  {
    upperTreshold: 1000,
    title: "Good air quality",
    subtitle: "Well ventilated",
  },
  {
    upperTreshold: 1200,
    title: "Good air quality",
    subtitle: "Well ventilated",
  },
  {
    upperTreshold: 9999,
    title: "Busy",
    subtitle: "People seem to like this place",
  },
];

export const pm25Text = [
  {
    upperTreshold: 35,
    title: "Low concentration",
    subtitle: "Contributing to good air quality",
  },
  {
    upperTreshold: 41,
    title: "Low concentration",
    subtitle: "Ensure windows are closed",
  },
  {
    upperTreshold: 47,
    title: "Moderate concentration",
    subtitle: "Ensure windows are closed",
  },
  {
    upperTreshold: 53,
    title: "Moderate concentration",
    subtitle: "Ensure windows are closed",
  },
  {
    upperTreshold: 9999,
    title: "High concentration",
    subtitle: "Ensure windows are closed",
  },
];

export const energyText = [
  {
    upperTreshold: 0.7,
    title: "We are helping the planet!",
    subtitle: "",
  },
  {
    upperTreshold: 1,
    title: "Low energy consumption",
    subtitle: "",
  },
  {
    upperTreshold: 1.5,
    title: "The standard office consumption",
    subtitle: "",
  },
  {
    upperTreshold: 2,
    title: "Our consumption is rising a bit",
    subtitle: "",
  },
  {
    upperTreshold: 9999,
    title: "We know it's high",
    subtitle: "",
  },
];

export const waterText = [
  {
    upperTreshold: 0.7,
    title: "We are helping the planet!",
    subtitle: "",
  },
  {
    upperTreshold: 1,
    title: "Low energy consumption",
    subtitle: "",
  },
  {
    upperTreshold: 1.5,
    title: "The standard office consumption",
    subtitle: "",
  },
  {
    upperTreshold: 2,
    title: "Our consumption is rising a bit",
    subtitle: "",
  },
  {
    upperTreshold: 9999,
    title: "We know it's high",
    subtitle: "",
  },
];
