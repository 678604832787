import React, { useEffect, useState } from "react";
import { HeatmapData } from "../../domain/HeatmapData";
import { getInterpolatedColor } from "../../usecases/getInterpolatedColor";
import { Variable } from "./ComfortVariables";
import { HeatmapVariable } from "./HeatmapVariables";
import { HeatmapTemplate } from "./templates/HeatmapTemplate";

interface IProps {
  floor: "1F" | "GF";
  heatmapData: HeatmapVariable;
  comfortData: Variable;
  show: boolean;
}

export const HeatmapWrapper: React.FC<IProps> = (props) => {
  const { heatmapData, comfortData } = props;

  const [heatmapApiData, setHeatmapApiData] = useState<HeatmapData[]>([]);
  const [currentValue, setCurrentValue] = useState<number>(0);
  const [outsideValue, setOutsideValue] = useState<number>(0);

  useEffect(() => {
    const callback = async () => {
      const valuesByFloor = await comfortData.getData();
      setOutsideValue(valuesByFloor.outside);

      const data_floor = await heatmapData.request(props.floor);
      if (data_floor) {
        setHeatmapApiData(data_floor.values);
        setCurrentValue(
          heatmapData.averageFunction(
            data_floor.values.map((it) => it.value)
          )
        );
      }
    };
    const intervalId = setInterval(callback, 1000 * 60 * 30);
    callback();
    return clearInterval(intervalId);
  }, [heatmapData, comfortData, props.floor]);

  let desksLabel = "";
  if (heatmapData.desksUnderThreshold) {
    desksLabel = heatmapData.desksUnderThreshold(heatmapApiData);
  } else if (heatmapData.outsideLabel) {
    desksLabel = heatmapData.outsideLabel(outsideValue, comfortData.units);
  }

  if (!props.show) {
    return <></>;
  }

  return (
    <HeatmapTemplate
      title={heatmapData.title(currentValue)}
      subtitle={heatmapData.subtitle(currentValue)}
      topic={comfortData.topic}
      titleFontSize={heatmapData.titleFontSize}
      subtitleFontSize={heatmapData.subtitleFontSize}
      value={currentValue}
      meterSubtitle={heatmapData.meterSubtitle}
      meterRange={comfortData.meterRange}
      optimalRange={comfortData.meterOptimalRange}
      meterLabels={comfortData.meterLabels.map((it) => +it)}
      units={comfortData.units}
      backgroundColor={getInterpolatedColor(
        comfortData.colorPalette,
        currentValue,
        comfortData.meterRange
      )}
      colorPalette={comfortData.colorPalette}
      processingGraphics={
        new URLSearchParams(window.location.search).get("animation") === "true"
          ? heatmapData.processingGraphics
          : undefined
      }
      heatmapData={heatmapApiData}
      desksUnderThreshold={desksLabel}
      desksUnderThresholdText={heatmapData.desksUnderThresholdText}
      heatmapMinLabel={heatmapData.heatmapMinLabel}
      heatmapMaxLabel={heatmapData.heatmapMaxLabel}
      floorplanPath={heatmapData.floorplanPath(props.floor)}
      floorplanMaskPath={heatmapData.floorplanMaskPath(props.floor)}
      mapImageOffset={heatmapData.mapImageOffset(props.floor)}
    />
  );
};
