import { lerpColor } from "./lerpColor";

export const getInterpolatedColor = (
  colors: { color: string; value: number }[],
  currentValue: number,
  meterRange: number[]
): string => {
  const currentValueCapped = Math.min(meterRange[1], currentValue);
  const percentage =
    (100 * (currentValueCapped - meterRange[0])) /
    (meterRange[1] - meterRange[0]);
  var interpolatedColor = colors[0].color;
  for (var i = 0; i < colors.length - 1; i++) {
    if (percentage > colors[i].value && percentage <= colors[i + 1].value) {
      const percentage_norm =
        (percentage - colors[i].value) /
        (colors[i + 1].value - colors[i].value);
      const color1 = colors[i].color;
      const color2 = colors[i + 1].color;
      interpolatedColor = lerpColor(color1, color2, percentage_norm);
    }
  }
  return interpolatedColor;
};
