import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import LightText from "./LightText";
import { motion } from "framer-motion";
import { IlightData } from "../LightVariables";

interface IProps {
	labels?: (number | string)[];
	justifyContent: "space-between" | "flex-start";
	titleColor?: string;
	lightData: IlightData | undefined;
}

export const LightMeter: React.FC<IProps> = (props) => {
	return (
		<Box className="LightMeter" position="relative" marginBottom="6rem" marginRight="3rem">
			<motion.div
				key="LightMeter"
				animate={{ y: [-50, -50, 0], opacity: [0, 0, 1] }}
				transition={{ ease: "easeInOut", duration: 1.1 }}
				style={{ opacity: 0 }}
			>
				<Flex justifyContent={props.justifyContent}>
					<LightText
						valueTemperature={props.lightData?.values.colorTemperatureCurrent[0].value}
						valueLuminance={props.lightData?.values.exteriorIlluminanceCurrent[0].value}
						titleColor={props.titleColor}
					></LightText>
				</Flex>
			</motion.div>
		</Box>
	);
};
