import { Box, Grid, GridItem, GridProps } from "@chakra-ui/react";
import React from "react";
import CurrentRadiationMeter from "../light/CurrentRadiationMeter";
import LightHorizontalBar from "../light/LightHorizontalBar";
import LightVerticalBar from "../light/LightVerticalBar";
import { IlightData } from "../LightVariables";
import { VerticalLineGraph } from "./VerticalLineGraph";

export const ProcessingVerticalBarsWithInnerLines: React.FC<{
	backgroundColor: string;
	lightData: IlightData | undefined;
}> = (props) => {
	let timestampList = props.lightData?.values.colorTemperature.map((element) => {
		return element.ts;
	});

	let data = timestampList?.map((e) => {
		return {
			ts: e,
			colorTemperature: props.lightData?.values.colorTemperature.filter((filterE) => e === filterE.ts)[0].value,
			illuminance: props.lightData?.values.exteriorIlluminance.filter((filterE) => e === filterE.ts)[0].value,
			radiation: props.lightData?.values.radiation[e],
			isCurrentElement: false,
		};
	});

	let lastElementData = {
		ts: props.lightData?.values.colorTemperatureCurrent[0].ts as never,
		colorTemperature: props.lightData?.values.colorTemperatureCurrent[0].value,
		illuminance: props.lightData?.values.exteriorIlluminanceCurrent[0].value,
		radiation: props.lightData?.values.radiationCurrent[props.lightData?.values.colorTemperatureCurrent[0].ts],
		isCurrentElement: true,
	};

	if (data && lastElementData) {
		data.push(lastElementData);
	}

	const maxRadiationPerTS = data && {
		...data.map((d) => d.radiation && Math.max(...d.radiation.map((subE) => subE.value))),
	};
	const maxRadiationOverall: number = maxRadiationPerTS
		? Math.max(...Object.values(maxRadiationPerTS as number[]))
		: -1;

	const luminanceValues = data && data.map((d) => parseInt(d.illuminance as string));
	const maxLuminanceValue = luminanceValues ? Math.max(...luminanceValues) : 0;

	const maxLuminanceScale = maxLuminanceValue > 30000 ? Math.ceil(maxLuminanceValue / 10000) * 10000 : 30000;

	const numberOfBars = 96; //48*30min = 24h

	const gridBaseStyle: GridProps = {
		gridTemplateColumns: `repeat(${numberOfBars}, 1fr)`,
		gridTemplateRows: "1fr",
	};
	return (
		<Box
			className="ProcessingVerticalBarsWithInnerLines"
			position="relative"
			height="100%"
			width="100%"
			background={"linear-gradient( #92C7DF,white);"}
			backgroundSize="cover"
		>
			{data &&
				maxRadiationOverall > -1 &&
				data.map((d, index) => (
					<Box key={index}>
						<CurrentRadiationMeter
							element={{
								...d,
								colorTemperature: d.colorTemperature || "",
								illuminance: d.illuminance || "",
								radiation: d.radiation!,
							}}
							maxRadiation={maxRadiationOverall}
						/>
					</Box>
				))}

			<LightVerticalBar maxLuminanceScale={maxLuminanceScale} />
			<Grid width="100vw" height="100vh" {...gridBaseStyle}>
				{data &&
					data.map((d) => (
						<GridItem height="100%" position="relative" key={d.ts}>
							<VerticalLineGraph
								element={{
									...d,
									colorTemperature: d.colorTemperature || "",
									illuminance: d.illuminance || "",
									radiation: d.radiation!,
								}}
								maxLuminanceScale={maxLuminanceScale}
							/>
						</GridItem>
					))}
			</Grid>
			<LightHorizontalBar />
		</Box>
	);
};
