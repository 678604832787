import { calculateAverageWithoutZeros } from "../../usecases/calculateAverageWithoutZeros";
import { getInterpolatedColor } from "../../usecases/getInterpolatedColor";
import { ColorPalette, soundPalette } from "../colors";
import { Floor } from "../misc/Floors";
import { ProcessingVerticalBarsWithInnerLines } from "./animation/ProcessingVerticalBarsWithInnerLines";
import jsonMockData from "../screens/animation/LightTestData/finalData.json";
export type Variable<T = IVariable> = T;

export type lightDataElementFormat = { ts: number; value: string }[];

export interface IlightData {
  values: {
    exteriorIlluminanceCurrent: lightDataElementFormat;
    exteriorIlluminance: lightDataElementFormat;
    colorTemperature: lightDataElementFormat;
    colorTemperatureCurrent: lightDataElementFormat;
    radiation: { [key: string]: { label: string; value: number }[] };
    radiationCurrent: { [key: string]: { label: string; value: number }[] };
    isCurrentElement?: boolean;
  };
}
export interface IVariable {
  getData: () => Promise<IlightData>;
  topic: string;
  meterRange: number[];
  meterLabels: (number | string)[];
  colorPalette: ColorPalette[];
  currentValue: (values: Record<Floor, number>) => number;
  titleColor?: string;
  backgroundColor: (colors: ColorPalette[], meterRange: number[]) => string;
  processingAnimation: React.FC<{
    backgroundColor: string;
    lightData: IlightData | undefined;
  }>;
}

export const lightData: Variable = {
  getData: async () => {
    const baseUrl =
      "https://d15o71z2fjy0l4.cloudfront.net";
    const url = baseUrl + "/api/light";
    // const url = "http://localhost:8080/api/light";
    const location = window.location.href;
    if (location.includes("sample")) {
      return jsonMockData;
    } else {
      const fetchedData = await fetch(url, {
        method: "GET",
      })
        .then((response) => response.json())
        .then((data) => data);
      return fetchedData;
    }
  },
  topic: "Spectometer",
  meterRange: [0, 24],
  meterLabels: ["0", "4", "8", "12", "16", "20", "24"],
  titleColor: "#000",
  colorPalette: soundPalette,
  currentValue: (floorValues) =>
    calculateAverageWithoutZeros([
      floorValues.firstFloor,
      floorValues.groundFloor,
      floorValues.lowerGround,
    ]),
  backgroundColor: (colors: ColorPalette[], meterRange: number[]) =>
    getInterpolatedColor(colors, 0, meterRange),
  processingAnimation: ProcessingVerticalBarsWithInnerLines,
};
