import React from "react";
import { Box, Flex } from "@chakra-ui/react";
import MeterBar from "./MeterBar";
import MeterText from "./MeterText";
import { motion } from "framer-motion";

interface IProps {
  value: number;
  subtitle: string;
  optimalRange?: number[];
  labels?: (number | string)[];
  range: number[];
  units: string;
  colorPalette: { color: string; value: number }[];
  secondaryTitle: string;
  secondarySubtitle: string;
  justifyContent: "space-between" | "flex-start";
  titleColor?: string;
}

export const MeterRoot: React.FC<IProps> = (props) => {
  return (
    <Box
      className="MeterRoot"
      position="relative"
      marginBottom="2rem"
      marginRight="4rem"
    >
      <motion.div
        key={Math.random()}
        animate={{ x: [-30, -30, 0], opacity: [0, 0, 1] }}
        transition={{ ease: "easeOut", duration: props.value === 0 ? 5 : 1 }}
        style={{ opacity: 0 }}
      >
        <Flex justifyContent={props.justifyContent}>
          <MeterText
            units={props.units}
            value={props.value}
            subtitle={props.subtitle}
          ></MeterText>
          <Flex
            flexDirection="column"
            justifyContent="end"
            maxWidth="40%"
            textAlign="left"
            color={"white"}
            lineHeight="4.75rem"
            fontSize="4.75rem"
            marginLeft="4rem"
            marginBottom="5rem"
            fontFamily="Arial"
          >
            <Box width="100%">{props.secondaryTitle}</Box>
            <Box
              width="150%"
              textAlign="left"
              lineHeight="3rem"
              fontSize="3rem"
              margin="0rem"
              fontFamily="Arial"
            >
              {props.secondarySubtitle}
            </Box>
          </Flex>
        </Flex>
      </motion.div>
      <MeterBar
        progress={props.value}
        optimalRange={props.optimalRange}
        range={props.range}
        labels={props.labels}
        colorPalette={props.colorPalette}
      ></MeterBar>
    </Box>
  );
};
