import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import React from "react";

interface IProps {
	valueTemperature: string | undefined;
	valueLuminance: string | undefined;
	titleColor?: string;
}

const LightText: React.FC<IProps> = (props) => {
	const conversion = Intl.NumberFormat("en-US");
	let valueTemperature = conversion.format(props.valueTemperature ? parseInt(props.valueTemperature) : 0);
	let valueLuminance = conversion.format(props.valueLuminance ? parseInt(props.valueLuminance) : 0);

	return (
		<Box
			textAlign="left"
			color={props.titleColor ? props.titleColor : "white"}
			margin="0rem 0rem 3rem 0rem"
			fontFamily="Arial"
		>
			<Flex width="100%" height="100%" flexDirection="column" justifyContent="flex-end">
				<Grid
					templateColumns="repeat(2, 1fr)"
					textAlign="left"
					fontSize="0.625rem"
					margin="0rem"
					fontFamily="Arial"
					fontWeight={"bold"}
					lineHeight="0.75rem"
				>
					<Text>Daylight Color Temperature / &nbsp;</Text>

					<Text>Daylight Illuminance</Text>
				</Grid>
				<Box>
					<Box display="inline" textAlign="left" fontSize="2rem" margin="0rem" fontFamily="Arial" fontWeight={"bold"}>
						{valueTemperature}K / {valueLuminance}lux
					</Box>
				</Box>
			</Flex>
		</Box>
	);
};
export default LightText;
