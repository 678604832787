import React, { useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import p5 from "p5";
import { P5Instance } from "../../misc/ReactP5WrapperComponent";

export const ProcessingLineGraphics: React.FC<{
  value: number;
  backgroundColor: string;
}> = (props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const sketchFunction = (sketch: P5Instance) => {
      const ranges = Math.round(3 + (props.value / 80) * 20);
      const width = window.innerWidth;
      const height = window.innerHeight;

      sketch.setup = () => {
        sketch.createCanvas(width, height);
        sketch.strokeWeight(0.4);
        sketch.noFill();
        sketch.stroke(255);
      };
      sketch.draw = () => {
        sketch.clear();

        for (let i = 0; i < ranges; i++) {
          // const paint = sketch.map(i, 0, ranges, 0, 255);
          // sketch.stroke(paint);

          sketch.beginShape();
          for (let x = -10; x < sketch.width + 11; x += 20) {
            const noise = sketch.noise(
              x *
                (0.0001 + 0.0009 * (Math.max(0, props.value - 40) / (55 - 40))),
              i * 0.03,
              sketch.frameCount * 0.008
            );
            const y = sketch.map(noise, 0, 1, 0, sketch.height);
            sketch.vertex(x, y);
          }
          sketch.endShape();
        }
      };
    };
    const elem = document.getElementsByClassName("p5Canvas")[0];
    if (elem) {
      elem.parentNode?.removeChild(elem);
    }
    const p5Instance = new p5(
      sketchFunction,
      rootRef.current === null ? undefined : rootRef.current
    );
    return () => p5Instance.remove();
  }, [props.value]);

  return (
    <Box
      className="ProcessingLineGraphics"
      position="relative"
      height="100%"
      width="100%"
      background={
        props.value === 0
          ? "#162431"
          : `linear-gradient(${props.backgroundColor},#162431)`
      }
      backgroundSize="cover"
      ref={rootRef}
    >
      <Box
        className="ProcessingLineGraphics"
        position="absolute"
        top="0"
        height="100%"
        width="100%"
      >
        {props.children}
      </Box>
    </Box>
  );
};
