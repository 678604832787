import React from "react";
import { Box } from "@chakra-ui/react";
import { getInterpolatedColor } from "../../../usecases/getInterpolatedColor";
import { P5Instance, Sketch } from "../../misc/ReactP5WrapperComponent";

import p5 from "p5";
import { motion } from "framer-motion";

const VerticalLineGraphBase: React.FC<{
	element: {
		ts: number;
		colorTemperature: string;
		illuminance: string;
		radiation: { label: string; value: number }[];
	};
	maxLuminanceScale: number;
}> = (props) => {
	const { element, maxLuminanceScale } = props;

	const numberOfBars = 96;

	const prepareBarStyle = (colorTemperature: number) => {
		return {
			height: "100%",
			backgroundColor: getInterpolatedColor(
				[
					{
						color: "#F27D27",
						value: 0,
					},
					{
						color: "#FCC900",
						value: 10,
					},
					{
						color: "#FFEE00",
						value: 20,
					},
					{
						color: "#FFFFC0",
						value: 30,
					},
					{
						color: "#FFFFEB",
						value: 40,
					},
					{
						color: "#FFFFFF",
						value: 50,
					},
					{
						color: "#EBF8FF",
						value: 60,
					},
					{
						color: "#C6EBFE",
						value: 70,
					},
					{
						color: "#9DDFF8",
						value: 80,
					},
					{
						color: "#4CCAF3",
						value: 90,
					},
					{
						color: "#1B9EC9",
						value: 90,
					},
				],
				colorTemperature,
				[1000, 10000],
			),
		};
	};

	const ref = React.useRef<any>(null);

	React.useEffect(() => {
		const sketch: Sketch = (p5: P5Instance) => {
			const barWidth = window.innerWidth / numberOfBars;
			const barHeight = 200;

			p5.setup = () => {
				p5.createCanvas(barWidth, barHeight);
				p5.clear();
				p5.stroke("#00000028");
				p5.strokeWeight(1);

				const arrayPoints = element.radiation
					? element.radiation.map((e: { label: string; value: number }) => {
							return e.value;
					  })
					: [];

				const maxValue = Math.max(...arrayPoints);

				arrayPoints.forEach((pointValue, index) => {
					const x1 = ((maxValue - pointValue) / maxValue) * barWidth;
					const y1 = ((5 * index) / (740 - 380)) * barHeight;

					if (index < arrayPoints.length - 1) {
						//draw line
						const x2 = ((maxValue - arrayPoints[index + 1]) / maxValue) * barWidth;
						const y2 = ((5 * (index + 1)) / (740 - 380)) * barHeight;
						p5.line(x1, y1, x2, y2);
					}
				});
			};
		};
		const p5Instance = new p5(sketch, ref.current === null ? undefined : ref.current);
		return () => p5Instance.remove();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const height: number = ((parseInt(element.illuminance) / maxLuminanceScale) * 100) / 2 + 50;
	return (
		<Box
			className="parent"
			height="100%"
			width="100%"
			position="relative"
			display="flex"
			flexDirection="column"
			justifyContent="end"
			key={props.element.ts}
		>
			<motion.div
				key={props.element.ts}
				className="animated"
				animate={{
					height: [`${(Math.random() * 0.3 + 0.7) * height}%`, `${height}%`],
				}}
				transition={{ ease: "easeInOut", duration: 1 }}
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "end",
				}}
			>
				<Box className="colored" {...prepareBarStyle(parseInt(element.colorTemperature))} />
			</motion.div>
			<Box ref={ref} position="absolute" height="200px" top="calc(50% + 3rem)" />
		</Box>
	);
};

export const VerticalLineGraph = React.memo(VerticalLineGraphBase, (previous, next) => {
	const areEquals =
		previous.element.colorTemperature === next.element.colorTemperature &&
		previous.element.illuminance === next.element.illuminance;
	return areEquals;
});
