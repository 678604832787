import { calculateAverageWithoutZeros } from "../../usecases/calculateAverageWithoutZeros";
import { getHeatmapData } from "../../usecases/getHeatmapData";
import { getInterpolatedColor } from "../../usecases/getInterpolatedColor";
import { average } from "../../utils/average";
import {
  co2Palette,
  ColorPalette,
  energyPalette,
  pm25Palette,
  soundPalette,
  temperaturePalette,
  waterPalette,
} from "../colors";
import { Floor } from "../misc/Floors";
import { ProcessingDopplerEffect } from "./animation/ProcessingDopplerEffect";
import { ProcessingLineGraphics } from "./animation/ProcessingLineGraphics";
import { ProcessingParticlesGraphics } from "./animation/ProcessingParticlesGraphics";
import { ProcessingFlow } from "./animation/ProcessingFlow";
import { ProcessingRoundedNoise } from "./animation/ProcessingRoundedNoise";
import { ProcessingWaterMetaball } from "./animation/ProcessingWaterMetaball";
import { co2Text, energyText, pm25Text, soundText, tempText } from "./Texts";
import { averageLog } from "../../utils/averageLog";

export type Variable<T = IVariable> = T;

export interface IVariable {
  getData: () => Promise<Record<Floor, number>>;
  topic: string;
  meterRange: number[];
  meterLabels: (number | string)[];
  meterOptimalRangeByFloor: Record<Floor, number[]>;
  meterOptimalRange?: number[];
  meterSubtitle: string;
  units: string;
  colorPalette: ColorPalette[];
  currentValue: (values: Record<Floor, number>) => number;
  backgroundColor: (
    colors: ColorPalette[],
    currentValue: number,
    meterRange: number[]
  ) => string;
  titlesSubtitles: {
    upperTreshold: number;
    title: string;
    subtitle: string;
  }[];
  processingAnimation: React.FC<{
    value: number;
    backgroundColor: string;
  }>;
}

export type LightVariable = Omit<IVariable, "getData"> & {
  getData: () => Promise<
    Record<
      "colorTemperatures",
      {
        date: Date;
        value: number;
      }[]
    >
  >;
};

export const temperatureData: Variable = {
  getData: async () => {
    const data_GF = await getHeatmapData("temperature", "GF", "comfort");
    const data_LG = await getHeatmapData("temperature", "LG", "comfort");
    const data_1F = await getHeatmapData("temperature", "1F", "comfort");
    const data_outside = await getHeatmapData(
      "temperature",
      "outside",
      "comfort"
    );
    return {
      lowerGround:
        data_LG.values.length > 0
          ? average(data_LG.values.map((it) => it.value))
          : 0,
      groundFloor:
        data_GF.values.length > 0
          ? average(data_GF.values.map((it) => it.value))
          : 0,
      firstFloor:
        data_1F.values.length > 0
          ? average(data_1F.values.map((it) => it.value))
          : 0,
      outside:
        data_outside.values.length > 0
          ? average(data_outside.values.map((it) => it.value))
          : 0,
    };
  },
  topic: "Air Temperature",
  meterRange: [16, 28],
  meterLabels: [16, 20, 24, 28],
  meterOptimalRangeByFloor: {
    lowerGround: [18, 26],
    groundFloor: [18, 26],
    firstFloor: [18, 26],
    outside: [18, 26],
  },
  meterOptimalRange: [18, 26],
  meterSubtitle: "in the office",
  units: " ºC",
  colorPalette: temperaturePalette,
  currentValue: (floorValues) =>
    calculateAverageWithoutZeros([
      floorValues.firstFloor,
      floorValues.groundFloor,
      floorValues.lowerGround,
    ]),
  backgroundColor: (
    colors: ColorPalette[],
    currentValue: number,
    meterRange: number[]
  ) => getInterpolatedColor(colors, currentValue, meterRange),
  titlesSubtitles: tempText,
  processingAnimation: ProcessingRoundedNoise,
};

export const soundData: Variable = {
  getData: async () => {
    const data_GF = await getHeatmapData("sound", "GF", "comfort");
    const data_1F = await getHeatmapData("sound", "1F", "comfort");
    return {
      lowerGround: 0,
      groundFloor:
        data_GF.values.length > 0
          ? average(data_GF.values.map((it) => it.value))
          : 0,
      firstFloor:
        data_1F.values.length > 0
          ? average(data_1F.values.map((it) => it.value))
          : 0,
      outside: 0,
    };
  },
  topic: "Sound level",
  meterRange: [30, 60],
  meterLabels: [30, 40, 50, 60],
  meterOptimalRangeByFloor: {
    lowerGround: [0, 50],
    groundFloor: [0, 50],
    firstFloor: [0, 50],
    outside: [0, 50],
  },
  meterSubtitle: "in the office",
  units: " dB(A)",
  colorPalette: soundPalette,
  currentValue: (floorValues) =>
    calculateAverageWithoutZeros([
      floorValues.firstFloor,
      floorValues.groundFloor,
      floorValues.lowerGround,
    ]),
  backgroundColor: (
    colors: ColorPalette[],
    currentValue: number,
    meterRange: number[]
  ) => getInterpolatedColor(colors, currentValue, meterRange),
  titlesSubtitles: soundText,
  processingAnimation: ProcessingLineGraphics,
};

export const co2Data: Variable = {
  getData: async () => {
    const data_GF = await getHeatmapData("co2", "GF", "comfort");
    const data_1F = await getHeatmapData("co2", "1F", "comfort");
    const data_LG = await getHeatmapData("co2", "LG", "comfort");
    const data_outside = await getHeatmapData("co2", "outside", "comfort");
    return {
      lowerGround:
        data_LG.values.length > 0
          ? average(data_LG.values.map((it) => it.value))
          : 0,
      groundFloor:
        data_GF.values.length > 0
          ? average(data_GF.values.map((it) => it.value))
          : 0,
      firstFloor:
        data_1F.values.length > 0
          ? average(data_1F.values.map((it) => it.value))
          : 0,
      outside:
        data_outside.values.length > 0
          ? average(data_outside.values.map((it) => it.value))
          : 0,
    };
  },
  topic: "CO₂ level",
  meterRange: [400, 1200],
  meterLabels: [400, 600, 800, 1000, 1200],
  meterOptimalRangeByFloor: {
    lowerGround: [400, 1000],
    groundFloor: [400, 1000],
    firstFloor: [400, 1000],
    outside: [400, 1000],
  },
  meterOptimalRange: [400, 1000],
  meterSubtitle: "in the office",
  units: " ppm",
  colorPalette: co2Palette,
  currentValue: (floorValues) =>
    calculateAverageWithoutZeros([
      floorValues.firstFloor,
      floorValues.groundFloor,
      floorValues.lowerGround,
    ]),
  backgroundColor: (
    colors: ColorPalette[],
    currentValue: number,
    meterRange: number[]
  ) => getInterpolatedColor(colors, currentValue, meterRange),
  titlesSubtitles: co2Text,
  processingAnimation: ProcessingFlow,
};

export const pm25Data: Variable = {
  getData: async () => {
    const data_GF = await getHeatmapData("pm25", "GF", "comfort");
    const data_1F = await getHeatmapData("pm25", "1F", "comfort");
    const data_LG = await getHeatmapData("pm25", "LG", "comfort");
    const data_outside = await getHeatmapData("pm25", "outside", "comfort");
    return {
      lowerGround:
        data_LG.values.length > 0
          ? average(data_LG.values.map((it) => it.value))
          : 0,
      groundFloor:
        data_GF.values.length > 0
          ? average(data_GF.values.map((it) => it.value))
          : 0,
      firstFloor:
        data_1F.values.length > 0
          ? average(data_1F.values.map((it) => it.value))
          : 0,
      outside:
        data_outside.values.length > 0
          ? average(data_outside.values.map((it) => it.value))
          : 0,
    };
  },
  topic: "Airborne Particles",
  meterRange: [0, 70],
  meterLabels: [0, 14, 28, 42, 56, 70],
  meterOptimalRangeByFloor: {
    lowerGround: [0, 35],
    groundFloor: [0, 35],
    firstFloor: [0, 35],
    outside: [0, 35],
  },
  meterOptimalRange: [0, 35],
  meterSubtitle: "in the office",
  units: " µg/m³",
  colorPalette: pm25Palette,
  currentValue: (floorValues) =>
    calculateAverageWithoutZeros([
      floorValues.firstFloor,
      floorValues.groundFloor,
      floorValues.lowerGround,
    ]),
  backgroundColor: (
    colors: ColorPalette[],
    currentValue: number,
    meterRange: number[]
  ) => getInterpolatedColor(colors, currentValue, meterRange),
  titlesSubtitles: pm25Text,
  processingAnimation: ProcessingParticlesGraphics,
};

export const energyData: Variable = {
  getData: async () => {
    const data = await getHeatmapData("energy", "", "planet");
    return {
      lowerGround: 0,
      groundFloor: 0,
      firstFloor:
        data.values.length > 0 ? average(data.values.map((it) => it.value)) : 0,
      outside: 0,
    };
  },
  topic: "Energy use",
  meterRange: [0, 2],
  meterLabels: ["0.0", "0.5", "1.0", "1.5", "2.0"],
  meterOptimalRangeByFloor: {
    lowerGround: [],
    groundFloor: [],
    firstFloor: [],
    outside: [],
  },
  meterOptimalRange: [0.5, 1.5],
  meterSubtitle: "in the office",
  units: " kW/h/m²",
  colorPalette: energyPalette,
  currentValue: (floorValues) =>
    calculateAverageWithoutZeros([
      floorValues.firstFloor,
      floorValues.groundFloor,
      floorValues.lowerGround,
    ]),
  backgroundColor: (
    colors: ColorPalette[],
    currentValue: number,
    meterRange: number[]
  ) => getInterpolatedColor(colors, currentValue, meterRange),
  titlesSubtitles: energyText,
  processingAnimation: ProcessingDopplerEffect,
};

export const waterData: Variable = {
  getData: async () => {
    const data = await getHeatmapData("energy", "", "planet");
    return {
      lowerGround: 0,
      groundFloor: 0,
      firstFloor:
        data.values.length > 0 ? average(data.values.map((it) => it.value)) : 0,
      outside: 0,
    };
  },
  topic: "Water use",
  meterRange: [0, 2],
  meterLabels: ["0.0", "0.5", "1.0", "1.5", "2.0"],
  meterOptimalRangeByFloor: {
    lowerGround: [],
    groundFloor: [],
    firstFloor: [],
    outside: [],
  },
  meterOptimalRange: [0.5, 1.5],
  meterSubtitle: "in the office",
  units: " l/h",
  colorPalette: waterPalette,
  currentValue: (floorValues) =>
    calculateAverageWithoutZeros([
      floorValues.firstFloor,
      floorValues.groundFloor,
      floorValues.lowerGround,
    ]),
  backgroundColor: (
    colors: ColorPalette[],
    currentValue: number,
    meterRange: number[]
  ) => getInterpolatedColor(colors, currentValue, meterRange),
  titlesSubtitles: energyText,
  processingAnimation: ProcessingWaterMetaball,
};
