import React, { useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import p5 from "p5";

const random = (min: number, max: number): number => {
  return min + Math.random() * (max - min);
};
const TWO_PI = 3.1415 * 2;

class Particle {
  radius;
  theta;
  scale;
  frameCount;
  sketch;
  halfWidth;
  halfHeight;
  speed;
  constructor(width: number, height: number, sketch: any) {
    this.frameCount = 1;
    this.radius = random(0, width / 2);
    this.theta = random(0, TWO_PI);
    this.scale = 0;
    this.speed = random(4, 18);
    this.sketch = sketch;
    this.halfWidth = width / 2;
    this.halfHeight = height / 2;
  }

  updateAndDraw() {
    this.frameCount = this.frameCount + 1;
    const x = this.radius * Math.cos(this.theta) + this.halfWidth;
    const y = this.radius * Math.sin(this.theta) + this.halfHeight;
    this.sketch.circle(x, y, this.scale);
    // now update size and position
    this.scale = this.scale + 0.013 * this.speed;
    this.radius = this.radius + this.radius * 0.001 * this.speed;
    this.theta = this.theta + this.theta * 0.001;
  }
}

export const ProcessingParticlesGraphics: React.FC<{
  value: number;
  backgroundColor: string;
}> = (props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const sketchFunction = (sketch: any) => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      const particleList: Particle[] = Array(
        Math.round(Math.min(props.value * 4 + 10, 170))
      )
        .fill(0)
        .map(() => new Particle(width, height, sketch));
      const diagonal = Math.sqrt(width * width + height * height) / 2;
      sketch.setup = () => {
        sketch.createCanvas(width, height);
        sketch.noFill();
        sketch.noStroke();
        sketch.fill("#FFFFFF99");
      };
      sketch.draw = () => {
        sketch.clear();
        for (let i = 0; i < particleList.length; i++) {
          particleList[i].updateAndDraw();
          if (particleList[i].radius > diagonal) {
            particleList[i] = new Particle(width, height, sketch);
          }
        }
      };
    };

    const elem = document.getElementsByClassName("p5Canvas")[0];
    if (elem) {
      elem.parentNode?.removeChild(elem);
    }
    const p5Instance = new p5(
      sketchFunction,
      rootRef.current === null ? undefined : rootRef.current
    );
    return () => p5Instance.remove();
  }, [props]);

  return (
    <Box
      className="ProcessingParticlesGraphics"
      position="relative"
      height="100%"
      width="100%"
      background={
        props.value === 0
          ? "#162431"
          : `linear-gradient(${props.backgroundColor},#162431)`
      }
      backgroundSize="cover"
      ref={rootRef}
    >
      <Box
        className="ProcessingParticlesGraphics"
        position="absolute"
        top="0"
        height="100%"
        width="100%"
      >
        {props.children}
      </Box>
    </Box>
  );
};
