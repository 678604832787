import React from "react";
import { Vector } from "p5";
import {
  P5Instance,
  ReactP5Wrapper,
  Sketch,
} from "../../misc/ReactP5WrapperComponent";
import { Box } from "@chakra-ui/react";

class Particle {
  loc;
  dir;
  speed;
  p5;

  constructor(_loc: Vector, _dir: Vector, _speed: number, p5: P5Instance) {
    this.loc = _loc;
    this.dir = _dir;
    this.speed = _speed;
    this.p5 = p5;
  }
  run(noiseScale: number, noiseStrength: number) {
    this.move(noiseScale, noiseStrength);
    this.checkEdges();
    this.update();
  }
  move(noiseScale: number, noiseStrength: number) {
    let angle =
      this.p5.noise(
        this.loc.x / noiseScale,
        this.loc.y / noiseScale,
        this.p5.frameCount / noiseScale
      ) *
      this.p5.TWO_PI *
      noiseStrength;
    this.dir.x = this.p5.cos(angle);
    this.dir.y = this.p5.sin(angle);
    var vel = this.dir.copy();
    var d = 1;
    vel.mult(this.speed * d);
    this.loc.add(vel);
  }
  checkEdges() {
    if (
      this.loc.x < 0 ||
      this.loc.x > this.p5.width ||
      this.loc.y < 0 ||
      this.loc.y > this.p5.height
    ) {
      this.loc.x = this.p5.random(this.p5.width * 1.2);
      this.loc.y = this.p5.random(this.p5.height);
    }
  }
  update() {
    this.p5.fill(255);
    this.p5.ellipse(this.loc.x, this.loc.y, this.loc.z);
  }
}

const sketch: Sketch = (p5: P5Instance) => {
  let num = 1000;
  let noiseScale = 500;
  let noiseStrength = 15;
  let particles: Particle[] = [];
  let color = "";

  p5.setup = () => {
    p5.createCanvas(p5.windowWidth, p5.windowHeight);
    p5.noStroke();
    // console.log(particles, num);
    particles = new Array(num);
    for (let i = 0; i < num; i++) {
      var loc = p5.createVector(
        p5.random(p5.width * 1.2),
        p5.random(p5.height),
        1.5
      );
      var angle = 0;
      var dir = p5.createVector(p5.cos(angle), p5.sin(angle));
      var speed = p5.random(0.5, 2);
      particles[i] = new Particle(loc, dir, speed, p5);
    }
  };
  p5.draw = () => {
    // Drawing code
    p5.fill(p5.color(color + "33"));
    p5.noStroke();
    p5.rect(0, 0, p5.width, p5.height);
    particles.forEach((p) => p.run(noiseScale, noiseStrength));
  };

  p5.updateWithProps = (props) => {
    if (props.value) {
      num = Math.round(props.value);
    }
    if (props.color) {
      color = props.color;
    }
    p5.setup();
  };
};

export const ProcessingFlow: React.FC<{
  value: number;
  backgroundColor: string;
}> = (props) => {
  return (
    <Box
      className="ProcessingPerlinNoise"
      position="relative"
      height="100%"
      width="100%"
    >
      <ReactP5Wrapper
        sketch={sketch}
        value={props.value}
        color={props.backgroundColor}
      />
      <Box
        background={
          props.value === 0
            ? "#162431"
            : `linear-gradient(#FFFFFF00 25%,#162431BF)`
        }
        backgroundSize="cover"
        height="100%"
        width="100%"
        position="absolute"
        top="0"
        left="0"
      />
    </Box>
  );
};
