import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";
import { HeatmapProcessing } from "./HeatmapProcessing";
import { ArupLogoWhite } from "../../misc/ArupLogoWhite";
import { HeatmapData } from "../../../domain/HeatmapData";
import MeterBar from "../meter/MeterBar";
import { ColorPalette } from "../../colors";

interface IProps {
  range: number[];
  minLabel: string;
  maxLabel: string;
  heatmapData: HeatmapData[];
  floorplanPath: string;
  floorplanMaskPath: string;
  mapImageOffset: { x: number; y: number; s: number; width: number };
  colorPalette: ColorPalette[];
}

const HeatmapRoot: React.FC<IProps> = (props) => {
  const windowBreakpoint = window.innerHeight < 1000;
  return (
    <Box height="100%" width="100%" position="relative">
      <ArupLogoWhite />
      <Flex
        className="legend-and-heatmap"
        justifyContent="space-between"
        flexDirection="column"
        height="calc(100% - 3rem)"
      >
        <Box
          className="legend-box"
          width="40%"
          margin="4.75rem 0rem 5.2rem 0rem"
        >
          <Flex className="legend-flex" justifyContent="space-between">
            <Text
              textAlign="left"
              color="white"
              fontSize="1rem"
              lineHeight="1rem"
              margin="0rem 0rem 1rem 0rem"
              fontFamily="Arial"
            >
              {props.minLabel}
            </Text>
            <Text
              textAlign="left"
              color="white"
              fontSize="1rem"
              margin="0rem 0rem 1rem 0rem"
              fontFamily="Arial"
            >
              {props.maxLabel}
            </Text>
          </Flex>
          <MeterBar
            progress={props.colorPalette[props.colorPalette.length - 1].value}
            range={[
              props.colorPalette[0].value,
              props.colorPalette[props.colorPalette.length - 1].value,
            ]}
            colorPalette={props.colorPalette}
            heightOverride="0.5rem"
            paddingBottomOverride="0.5rem"
          ></MeterBar>
        </Box>

        <Box
          className="heatmap-processing-box"
          paddingRight={windowBreakpoint ? "8rem" : "0px"}
          marginRight="90px"
        >
          <HeatmapProcessing
            range={props.range}
            heatmapData={props.heatmapData}
            mapImageOffset={props.mapImageOffset}
            floorplanPath={props.floorplanPath}
            floorplanMaskPath={props.floorplanMaskPath}
            colorPalette={props.colorPalette}
          />
        </Box>
      </Flex>
    </Box>
  );
};
export default HeatmapRoot;
