import { HeatmapData } from "../domain/HeatmapData";

type TelemetryType =
  | "pm25"
  | "co2"
  | "sound"
  | "temperature"
  | "energy"
  | "water";
type Domain = "comfort" | "planet";

export type FloorName = "GF" | "LG" | "1F" | "outside" | "";

function wait(delay: number) {
  return new Promise((resolve) => setTimeout(resolve, delay));
}

function fetchRetry(
  url: string,
  fetchOptions = {},
  delay: number,
  tries: number
): Promise<any> {
  const onError = (err: any) => {
    const triesLeft: number = tries - 1;
    if (!triesLeft) {
      throw err;
    }
    return wait(delay).then(() => {
      console.log("retry");
      return fetchRetry(url, fetchOptions, delay, triesLeft);
    });
  };
  return fetch(url, fetchOptions).catch(onError);
}

export const getHeatmapData = async (
  telemetryType: TelemetryType,
  floor: FloorName,
  domain: Domain
): Promise<{
  values: HeatmapData[];
}> => {
  const baseUrl = "https://d15o71z2fjy0l4.cloudfront.net";
  return fetchRetry(
    `${baseUrl}/api/${domain}/${telemetryType}/${floor}`,
    {
      method: "GET",
    },
    500,
    3
  )
    .then((response) => response.json())
    .then((data) => data);
};
