import { Box } from "@chakra-ui/react";
import { Shader } from "p5";
import {
  P5Instance,
  ReactP5Wrapper,
  Sketch,
} from "../../misc/ReactP5WrapperComponent";

// https://openprocessing.org/sketch/1293878

const sketch: Sketch = (p5: P5Instance) => {
  let shader: Shader;

  const balls: any[] = [];
  const radius = 20;
  const num = 50;
  const maxSpeed = 9;
  p5.preload = () => {
    //@ts-ignore
    shader = getShader(p5._renderer);
  };
  p5.setup = () => {
    p5.pixelDensity(1);
    p5.createCanvas(p5.windowWidth, p5.windowHeight, p5.WEBGL);
    p5.noStroke();
    for (let i = 0; i < num; i++) {
      let a = p5.random(2 * p5.PI);
      let b = {
        x: p5.random(p5.width),
        y: p5.random(p5.height),
        vx: p5.cos(a),
        vy: p5.sin(a),
        rad: 20,
      };
      balls.push(b);
    }
  };
  p5.draw = () => {
    if (p5.frameCount < 30) {
      return;
    }
    let data = [];

    console.log(p5.mouseX, p5.mouseY);
    for (let b of balls) {
      let f = num / radius;
      let a = p5.atan2(p5.height / 2 - b.y, p5.width / 2 - b.x);
      b.vx = p5.constrain(b.vx + (p5.cos(a) * f) / b.rad, -maxSpeed, maxSpeed);
      b.vy = p5.constrain(b.vy + (p5.sin(a) * f) / b.rad, -maxSpeed, maxSpeed);
    }

    for (let b of balls) {
      b.x += b.vx;
      b.y += b.vy;
      if (b.x < b.rad) {
        b.x = b.rad;
        b.vx *= -1;
      }
      if (b.x > p5.width - b.rad) {
        b.x = p5.width - b.rad;
        b.vx *= -1;
      }
      if (b.y < b.rad) {
        b.y = b.rad;
        b.vy *= -1;
      }
      if (b.y > p5.height - b.rad) {
        b.y = p5.height - b.rad;
        b.vy *= -1;
      }
      data.push(b.x, b.y, b.rad);
    }
    p5.shader(shader);
    shader.setUniform("balls", data);
    p5.rect(0, 0, p5.width, p5.height);
    //print(frameRate());
  };

  function getShader(_renderer: any) {
    const vert = `
      precision highp float;
      attribute vec3 aPosition;
      attribute vec2 aTexCoord;
      varying vec2 vTexCoord;
      void main() {
        vTexCoord = aTexCoord;
        vec4 positionVec4=vec4(aPosition,1.);
        positionVec4.xy=positionVec4.xy*2.-1.; 
        gl_Position = positionVec4;
      }
    `;
    const frag = `
      precision highp float;
      varying vec2 vTexCoord;
      const float WIDTH=${p5.windowWidth}.;
      const float HEIGHT=${p5.windowHeight}.;
      uniform vec3 balls[${num}];
      void main() {
        float x=vTexCoord.x*WIDTH;
        float y=HEIGHT-vTexCoord.y*HEIGHT;
        float r=0.;
        for (int i=0;i<${num};i++) {
          vec3 b=balls[i];
          r+=b.z*b.z/((b.x-x)*(b.x-x)+(b.y-y)*(b.y-y));
        }
        if (r>.51) r=1.02-r;
        r=1./(1.+exp(-50.*(r-0.5)));
        gl_FragColor = vec4(vec3(1.),r);
      }
    `;
    //@ts-ignore
    return new Shader(_renderer, vert, frag);
  }
};

export const ProcessingWaterMetaball: React.FC<{
  value: number;
  backgroundColor: string;
}> = (props) => {
  return (
    <Box
      className="ProcessingWaterMetaball"
      position="relative"
      height="100%"
      width="100%"
      background={
        props.value === 0
          ? "#162431"
          : `linear-gradient(${props.backgroundColor},#162431)`
      }
      backgroundSize="cover"
    >
      <Box position="absolute" top="0" left="0" width="100vw" height="100vh">
        <ReactP5Wrapper
          sketch={sketch}
          value={props.value}
          backgroundColor={props.backgroundColor}
        ></ReactP5Wrapper>
      </Box>
      {props.children}
    </Box>
  );
};
