import React from "react";
import { Box, Text } from "@chakra-ui/react";

interface IProps {
  title: string | undefined;
  subtitle: string | undefined;
  topic: string;
  titleFontSize?: string;
  subtitleFontSize?: string;
  titleColor?: string;
}

const HeaderRoot: React.FC<IProps> = (props) => {
  return (
    <Box position="relative">
      <Text
        textAlign="left"
        color={props.titleColor ? props.titleColor : "white"}
        lineHeight="3rem"
        fontSize="3rem"
        margin="0rem"
        padding="0rem 0rem 3rem 0rem"
        fontFamily="Arial"
        fontWeight="bold"
      >
        {props.topic}
      </Text>
      <Text
        textAlign="left"
        color={props.titleColor ? props.titleColor : "white"}
        fontFamily="serif"
        lineHeight={props.titleFontSize ? props.titleFontSize : "6rem"}
        fontSize={props.titleFontSize ? props.titleFontSize : "6rem"}
        margin="0rem"
        padding="0rem 0rem 2rem 0rem"
      >
        {props.title}
      </Text>
      <Text
        textAlign="left"
        color={props.titleColor ? props.titleColor : "white"}
        fontFamily="serif"
        lineHeight={props.subtitleFontSize ? props.subtitleFontSize : "3.2rem"}
        fontSize={props.subtitleFontSize ? props.subtitleFontSize : "3.2rem"}
        margin="0rem"
        padding="0rem"
        paddingLeft="5px"
      >
        {props.subtitle}
      </Text>
    </Box>
  );
};
export default HeaderRoot;
