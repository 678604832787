import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";
import { GeoGeometryObjects } from "d3";

import newyork from "./newyork.json";
import { fetchFromBackend } from "../../usecases/fetchFromBackend";

export const Prototype = () => {
  const [valueFromBackend, setValueFromBackend] = useState("-");

  const d3Root = useRef<HTMLDivElement>(null);
  var width = 300;
  var height = 300;

  var projection = d3
    .geoIdentity()
    .fitSize([width, height], newyork as unknown as GeoGeometryObjects);
  var path = d3.geoPath(projection);

  useEffect(() => {
    d3.select(d3Root.current)
      .append("p")
      .style("color", "#000000")
      .text("d3 geoJson");
    d3.select(d3Root.current)
      .append("svg")
      .attr("width", width)
      .attr("height", height)
      .selectAll("path")
      .data(newyork.features)
      .enter()
      .append("path")
      .attr("d", path as unknown as string);

    fetchFromBackend().then((reponse) =>
      reponse.text().then((text) => setValueFromBackend(text))
    );
  }, [height, width, path]);

  return (
    <div>
      <header className="App-header">
        <div ref={d3Root}></div>
        <section>
          <p>mp4 video</p>
          <video width="320" height="240" autoPlay controls loop muted>
            <source src="sky.mov" type="video/mp4" />
          </video>
        </section>
        <section>
          <p>WebM video</p>
          <video width="320" height="240" autoPlay controls loop muted>
            <source src="small.webm" type="video/webm" />
          </video>
        </section>
        <section>
          <p>Backend</p>
          <h1>{valueFromBackend}</h1>
        </section>
        <section>
          <p>Gif</p>
          <img
            src="http://i.stack.imgur.com/SBv4T.gif"
            alt="this slowpoke moves"
            width="250"
          />{" "}
        </section>
        <section>
          <p>Youtube</p>
          <iframe
            width="560"
            height="315"
            src="https://www.youtube.com/embed/IMVjlgWby74?autoplay=1&mute=1"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </section>
      </header>
    </div>
  );
};
