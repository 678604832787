import React from "react";
import { Box, Text } from "@chakra-ui/react";

export const SoundHeader = (props: { decibels: number }) => {
  const title = (decibels: number) => {
    if (decibels < 45) {
      return "Do you hear that?";
    } else if (decibels < 55) {
      return "The office is starting to fill up.";
    } else if (decibels < 70) {
      return "We're full!";
    } else {
      return "Silence please";
    }
  };
  const subtitle = (decibels: number) => {
    if (decibels < 45) {
      return "Silence is the new luxury.";
    } else if (decibels < 55) {
      return "Remember, noise is pollution.";
    } else if (decibels < 70) {
      return "Please, if you must make a noise, make it quietly.";
    } else {
      return "Let your success make all the noise.";
    }
  };
  return (
    <Box position="relative">
      <Text
        textAlign="left"
        color="#F22B32"
        fontSize="3.2rem"
        margin="0rem"
        fontFamily="Arial"
      >
        Sound level
      </Text>
      <Text
        textAlign="left"
        color="white"
        fontFamily="serif"
        fontSize="6rem"
        margin="0rem"
      >
        {title(props.decibels)}
      </Text>
      <Text
        textAlign="left"
        color="white"
        fontFamily="serif"
        fontSize="3.2rem"
        margin="0rem"
      >
        {subtitle(props.decibels)}
      </Text>
    </Box>
  );
};
