import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { LightHeaderRoot } from "./light/LightHeaderRoot";
import { LightMeter } from "./light/LightMeter";
import { IlightData, Variable } from "./LightVariables";

export const LightWrapper: React.FC<{
	showFloorDetails: boolean;
	variable: Variable;
	show: boolean;
}> = (props) => {
	const [lightData, setLightData] = useState<IlightData | undefined>(undefined);
	const { getData, topic, titleColor, colorPalette, backgroundColor, meterRange } = props.variable;

	const checkFetchReturnedAllValues = (data: any) => {
		if (data.values) {
			if (
				data.values.colorTemperature &&
				Object.entries(data.values.colorTemperature).length &&
				data.values.colorTemperatureCurrent &&
				Object.entries(data.values.colorTemperatureCurrent).length &&
				data.values.exteriorIlluminance &&
				Object.entries(data.values.exteriorIlluminance).length &&
				data.values.exteriorIlluminanceCurrent &&
				Object.entries(data.values.exteriorIlluminanceCurrent).length &&
				data.values.radiation &&
				Object.entries(data.values.radiation).length &&
				data.values.radiationCurrent &&
				Object.entries(data.values.radiationCurrent).length
			) {
				setLightData(data);
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (!lightData) {
			getData().then((data) => checkFetchReturnedAllValues(data));
		} else {
			const intervalId = setInterval(() => getData().then((data) => checkFetchReturnedAllValues(data)), 1000 * 60);
			return () => clearInterval(intervalId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lightData]);

	const d = new Date();
	let hour = d.getHours();
	let currentTitle = "";
	let currentSubtitle = "";

	if (lightData) {
		const currentIlluminance = parseInt(lightData.values.exteriorIlluminanceCurrent[0].value);
		if (hour >= 7 && hour < 9) {
			if (currentIlluminance >= 4000) {
				currentSubtitle = "Looks like a clear day ahead";
			} else if (currentIlluminance > 1000 && currentIlluminance < 4000) {
				currentSubtitle = "Looks like a typical London day";
			} else if (currentIlluminance < 1000) {
				currentSubtitle = "Looks like a very cloudy day";
			}
		} else if (hour >= 9 && hour <= 16) {
			if (currentIlluminance <= 1000) {
				currentSubtitle = "Above average cloudy";
			} else if (currentIlluminance > 1000 && currentIlluminance < 3000) {
				currentSubtitle = "Average London Clouds";
			} else if (currentIlluminance > 3000 && currentIlluminance < 15000) {
				currentSubtitle = "Below average London clouds";
			} else if (currentIlluminance > 15000) {
				currentSubtitle = "Sunny day in London!";
			}
		}
	}

	if (hour >= 7 && hour < 13) {
		currentTitle = "Good morning!";
	} else if (hour >= 13 && hour < 14) {
		currentTitle = "Lunch break!";
	} else if (hour >= 14 && hour < 18) {
		currentTitle = "Good afternoon!";
	} else {
		currentTitle = "Time to go home!";
	}

	return (
		<>
			<Box className="LightWrapper" width="100vw" height="100vh" backgroundColor="gray">
				<Box position="absolute" width="100%" height="100%" top="0" left="0" zIndex={0}>
					<props.variable.processingAnimation
						backgroundColor={backgroundColor(colorPalette, meterRange)}
						lightData={lightData}
					/>
				</Box>
				<Box height="100%" position="absolute" width="100%" top="0" left="0" zIndex={1} padding="3rem 0rem 0rem 3.5rem">
					<Flex height="100%" flexDirection="column" justifyContent="space-between">
						<LightHeaderRoot topic={topic} title={currentTitle} subtitle={currentSubtitle}></LightHeaderRoot>
						<LightMeter
							labels={props.variable.meterLabels}
							justifyContent="flex-start"
							titleColor={titleColor}
							lightData={lightData}
						></LightMeter>
					</Flex>
				</Box>
			</Box>
		</>
	);
};
