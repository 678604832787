import { Box } from "@chakra-ui/react";
import p5 from "p5";
import React, { useRef } from "react";
import { HeatmapData } from "../../../domain/HeatmapData";
import { getInterpolatedColor } from "../../../usecases/getInterpolatedColor";
import { average } from "../../../utils/average";
import { distSq } from "../../../utils/distSq";
import { map } from "../../../utils/map";
import { ColorPalette } from "../../colors";
import { ReactP5Wrapper, Sketch } from "../../misc/ReactP5WrapperComponent";

interface IProps {
  range: number[];
  floorplanPath: string;
  floorplanMaskPath: string;
  heatmapData: HeatmapData[];
  colorPalette: ColorPalette[];
  mapImageOffset: { x: number; y: number; s: number; width: number };
}

const realWorldXmin = 0;
const realWorldXmax = 6000;

const pixelsXmin = 0;
const pixelsXmax = 800;

var cellSize = 8;
const nRowsX = pixelsXmax / cellSize;
const nColsY = nRowsX;

class Cell {
  rowX;
  colY;
  values: number[];
  value;
  x;
  y;
  distanceSq;
  constructor(rowX: number, colY: number) {
    this.rowX = rowX;
    this.colY = colY;
    this.value = 0;
    this.x = rowX * cellSize;
    this.y = colY * cellSize;
    this.values = [];
    this.distanceSq = 1000000;
  }
  applyValue(newValue: number, distanceSq: number) {
    this.values.push(newValue);
    this.value = average(this.values);
    this.distanceSq = Math.min(distanceSq, this.distanceSq);
  }
}

class HeatSource {
  x;
  y;
  heat;
  radiusSq;
  constructor(x: number, y: number, heat: number, radiusSq: number) {
    this.x = x;
    this.y = y;
    this.heat = heat;
    this.radiusSq = radiusSq;
  }
  affect(cell: Cell) {
    const tempDistSq = distSq(this, cell);
    if (tempDistSq < this.radiusSq) {
      const factor = 1 - tempDistSq / this.radiusSq;
      cell.applyValue(this.heat * 0.75 + this.heat * factor * 0.25, tempDistSq);
    }
  }
}

const createCellsWithHeat = (
  heatmapData: HeatmapData[]
): { cells: Cell[]; heatSources: HeatSource[] } => {
  if (heatmapData.length === 0) {
    return { cells: [], heatSources: [] };
  }

  const heatSources = heatmapData.map(
    (it) =>
      new HeatSource(
        map(
          it.coordinates.x,
          realWorldXmin,
          realWorldXmax,
          pixelsXmin,
          pixelsXmax
        ),
        map(
          it.coordinates.y,
          realWorldXmin,
          realWorldXmax,
          pixelsXmin,
          pixelsXmax
        ),
        Number(it.value),
        6400
      )
  );

  const cells = Array(nRowsX * nColsY);
  for (let index = 0; index < cells.length; index++) {
    const row = index - Math.floor(index / nRowsX) * nRowsX;
    const col = Math.floor(index / nRowsX);
    cells[index] = new Cell(row, col);
  }
  heatSources.forEach((heatSource) => {
    cells.forEach((cell) => {
      heatSource.affect(cell);
    });
  });
  return { cells, heatSources };
};

export const HeatmapProcessing: React.FC<IProps> = (props) => {
  const rootRef = useRef<HTMLDivElement>(null);
  // Code block to calcuate the offsets
  /*   let fooX = -74;
  let fooY = -25;
  let fooS = 1.16;
  useEffect(() => {
    function input(event: any) {
      if (event.key === "q") {
        fooX++;
      }
      if (event.key === "a") {
        fooX--;
      }
      if (event.key === "w") {
        fooY++;
      }
      if (event.key === "s") {
        fooY--;
      }
      if (event.key === "e") {
        fooS = fooS + 0.01;
      }
      if (event.key === "d") {
        fooS = fooS - 0.01;
      }
      console.log(fooX, fooY, fooS);
    }
    document.addEventListener("keydown", input, false);
    return () => {
      document.removeEventListener("keydown", input, false);
    };
  }); */
  const sketch: Sketch = (p5) => {
    let floorplan: p5.Image | undefined = undefined;
    let backgroundImageOutside: p5.Image | undefined = undefined;
    let graySquare: p5.Image | undefined = undefined;
    let ratio = 1;
    p5.preload = () => {
      floorplan = p5.loadImage(
        props.floorplanPath,
        () => console.log("sketch loadImage success", props.floorplanPath),
        (err) =>
          console.log("sketch loadImage error", props.floorplanPath, err)
      );
      

      backgroundImageOutside = p5.loadImage(
        props.floorplanMaskPath,
        () => console.log("sketch loadImage success", props.floorplanMaskPath),
        (err) =>
          console.log("sketch loadImage error", props.floorplanMaskPath, err)
      );
      graySquare = p5.loadImage("/gray-square.png");
    };

    p5.setup = () => {
      const { cells, heatSources } = createCellsWithHeat(props.heatmapData);
      if (rootRef.current) {
        const foo = Math.min(
          rootRef.current.clientWidth,
          rootRef.current.clientHeight
        );
        ratio = foo / (pixelsXmax - pixelsXmin);
      } else {
        ratio = 1;
      }
      // console.log(rootRef.current, ratio);

      p5.createCanvas(pixelsXmax * ratio, pixelsXmax * ratio);
      p5.noStroke();
      cells.forEach((cell) => {
        if (cell.value > 0) {
          const color = getInterpolatedColor(
            props.colorPalette,
            cell.value,
            props.range
          );
          p5.fill(color);
          // this is a magic number to offset the cells to the left.
          const centerX =
            (cell.rowX * cellSize + props.mapImageOffset.x) *
            props.mapImageOffset.s *
            ratio;
          const centerY =
            (cell.colY * cellSize + props.mapImageOffset.y) *
            props.mapImageOffset.s *
            ratio;
          p5.circle(
            centerX,
            centerY,
            cellSize * ratio * (1 - cell.distanceSq / 6400) * 1.5
          );
          p5.fill("pink");
          // p5.rect(centerX, centerY, 1, 1);
        }
      });
      if (backgroundImageOutside && floorplan && graySquare) {
        // this is a collection of masks to hide the graphics outside the floor plan
        /**
         * We skip next line type checking as p5 library is not providing type REMOVE for blendMode()
         * although it works and it's documents in its web.
         * */
        //@ts-ignore
        p5.blendMode(p5.REMOVE);
        p5.image(
          graySquare,
          0,
          (backgroundImageOutside.height / backgroundImageOutside.width) *
            props.mapImageOffset.width *
            ratio,
          pixelsXmax * ratio,
          pixelsXmax * ratio
        );
        p5.image(
          backgroundImageOutside,
          0,
          0,
          props.mapImageOffset.width * ratio,
          (backgroundImageOutside.height / backgroundImageOutside.width) *
            props.mapImageOffset.width *
            ratio
        );

        p5.blendMode(p5.BLEND);
        // end of the masks
        p5.image(
          floorplan,
          0,
          0,
          props.mapImageOffset.width * ratio,
          (floorplan.height / floorplan.width) *
            props.mapImageOffset.width *
            ratio
        );
      }
    };
    // Code block to calcuate the offsets
    /*     p5.draw = () => {
      _heatSources.forEach((it) => {
        p5.fill("yellow");
        p5.rect(
          (it.x + fooX) * fooS * ratio,
          (it.y + fooY) * fooS * ratio,
          1,
          1
        );
      });
      if (floorplan) {
        p5.image(
          floorplan,
          0,
          props.mapImageOffset.y * ratio,
          props.mapImageOffset.width * ratio,
          (floorplan.height / floorplan.width) *
            props.mapImageOffset.width *
            ratio
        );
      }
    }; */
  };
  return (
    <Box className="HeatmapProcessing" ref={rootRef} minHeight="71vh">
      <ReactP5Wrapper
        key={new Date().getTime()}
        sketch={sketch}
        floorplanPath={props.floorplanPath}
        floorplanMaskPath={props.floorplanMaskPath}
        range={props.range}
        colorPalette={props.colorPalette}
        mapImageOffset={props.mapImageOffset}
        heatmapData={props.heatmapData}
      />
    </Box>
  );
};
