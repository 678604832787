import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/react";

export const SoundGauge = (props: { decibels: number }) => {
  const [currentDecibels, setCurrentDecibels] = useState<number>(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentDecibels((state) => {
        return state + (props.decibels - state) / 3;
      });
    }, 50);
    return () => clearInterval(interval);
  }, [currentDecibels, props]);

  return (
    <Box position="relative">
      <Box
        textAlign="left"
        color="#F22B32"
        fontSize="7.5rem"
        margin="1rem 0rem"
        fontFamily="Arial"
      >
        <b>{Math.round(currentDecibels)}</b>
        <Box
          display="inline"
          textAlign="left"
          color="#F22B32"
          fontSize="6rem"
          margin="0rem"
          fontFamily="Arial"
        >
          {" dB"}
        </Box>
      </Box>

      <Box border="1px solid white" borderRadius="0.5rem" position="relative">
        <Box
          margin="0.5rem"
          width={(currentDecibels / 80) * 100 + "%"}
          height="2rem"
          backgroundColor="#F22B32"
          borderRadius="0.2rem"
        ></Box>
        {renderLabel(0)}
        {renderVerticalBarWithLabel(45)}
        {renderVerticalBarWithLabel(55)}
        {renderVerticalBarWithLabel(70)}
        {renderLabel(80)}
      </Box>
    </Box>
  );
};

const renderLabel = (decibels: number) => {
  return (
    <Box position="absolute" top="0rem" left={(decibels / 80) * 100 + "%"}>
      <Text
        position="absolute"
        top="-2.2rem"
        left="-0.4rem"
        textAlign="left"
        color="white"
        fontSize="0.9rem"
      >
        {decibels}
      </Text>
    </Box>
  );
};

const renderVerticalBarWithLabel = (decibels: number) => {
  return (
    <>
      {" "}
      <Box
        position="absolute"
        top="0rem"
        left={(decibels / 80) * 100 + "%"}
        height="3rem"
        border="0.5px solid white"
      ></Box>
      {renderLabel(decibels)}
    </>
  );
};
