import React from "react";
import { Box, Text } from "@chakra-ui/react";
import { motion } from "framer-motion";

interface IProps {
  title: string | undefined;
  subtitle: string | undefined;
  topic: string;
}

export const LightHeaderRoot: React.FC<IProps> = (props) => {
  return (
    <motion.div
      key={"LightHeaderRoot"}
      animate={{ x: [50, 50, 0], opacity: [0, 0, 1] }}
      transition={{ ease: "easeInOut", duration: 1.1 }}
      style={{ opacity: 0 }}
    >
      <Box position="relative">
        <Text
          textAlign="left"
          color={"black"}
          lineHeight="1rem"
          fontSize="1rem"
          margin="0rem"
          paddingBottom="0.4rem"
          fontFamily="Arial"
          fontWeight="bold"
        >
          {props.topic}
        </Text>
        <Text
          textAlign="left"
          color={"black"}
          fontFamily="serif"
          lineHeight={"4rem"}
          fontSize={"4rem"}
          margin="0rem"
          padding="0rem 0rem 1rem 0rem"
        >
          {props.title}
        </Text>
        <Text
          textAlign="left"
          color={"black"}
          fontFamily="serif"
          lineHeight={"1.5rem"}
          fontSize={"1.5rem"}
          margin="0rem"
          padding="0rem"
          paddingLeft="5px"
        >
          {props.subtitle}
        </Text>
      </Box>
    </motion.div>
  );
};
